.detail-meta
	display: flex
	align-items: center
	+content-margins
		margin-left: r(20px)

.detail-tag
	span
		display: inline-block
		padding: r(4px) r(8px)
		border-radius: r(4px)
		+bg(main)
		+text(blue)
		+fz-lh(12px,16px)

.detail-date 
	+fz-lh(14px,20px)
	+text(grey-999)
	span
		margin-right: r(4px)

.detail-heading
	border-bottom: 1px solid color(grey-light)
	padding-bottom: r(20px)
	margin-bottom: 1rem

.detail-social
	+mq-min(xxl)
		position: absolute
		top: 0.5rem
		height: 100%
		right: 100%
		margin-right: r(40px)
	a
		+circle(2rem)
		border: 1px solid color(grey-666)
		+text(grey-666)
		display: flex
		+flex-center
	ul
		+mq-max(xxl)
			display: flex
			margin-top: r(20px)
			+content-margins
				margin-left: 0.5rem
		+mq-min(xxl)
			position: sticky
			top: r(160px)
			+content-margins
				margin-top: 0.5rem

.news-detail-page
	.news-subnav-module
		display: none