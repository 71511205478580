.article-content
	line-height: 1.33333
	font-size: r(18px)
	font-weight: 300
	> *
		+ *
			margin-top: r(16px)
	ul
		list-style: disc
		padding-left: 1.25rem
		li
			&::marker
				+text(blue)
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top
	h2
		font-size: r(20px)
		font-weight: 700
	h3
		font-size: r(18px)
		font-weight: 600
	* + h2
		margin-top: r(28px)
	hr
		background: none
		height: auto
		border-top: 1px solid color(grey-bright)
	a
		color: #55c2e0
		text-decoration: underline

.expand-content
	overflow: hidden
	&.show
		max-height: none !important