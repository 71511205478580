@charset "UTF-8";
/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Content margins -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

*, *:before, *:after {
  outline: none;
  box-sizing: border-box; }

img, video, iframe {
  max-width: 100%; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

a {
  color: inherit; }

strong, b {
  font-weight: 700; }

input, select, textarea, button {
  font-family: inherit; }

button {
  cursor: pointer; }

/* ==========================================================================
    GRIDLEX
    Just a Flexbox Grid System - v. 2.7.1
========================================================================== */
.row {
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  margin-left: -1.875rem;
  margin-bottom: -1.875rem; }

.row > * {
  width: 100%; }

.row > * {
  box-sizing: border-box;
  padding: 0 0 1.875rem 1.875rem; }

.col {
  flex: 1 1 0%; }

@media screen and (max-width: 768px) {
  .row {
    margin-left: -1.5rem;
    margin-bottom: -1.5rem; }
  .row > * {
    padding: 0 0 1.5rem 1.5rem; } }

/************************
    HELPERS SUFFIXES
*************************/
.row.no-gutter {
  margin: 0; }
  .row.no-gutter > * {
    padding: 0; }

.row.equal-height > * {
  align-self: stretch; }
  .row.equal-height > * > * {
    height: 100%; }

/************************
    GRID BY NUMBER
*************************/
.row.row-1 > * {
  flex: 0 0 100%;
  max-width: 100%; }

.row.row-2 > * {
  flex: 0 0 50%;
  max-width: 50%; }

.row.row-3 > * {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row.row-4 > * {
  flex: 0 0 25%;
  max-width: 25%; }

.row.row-5 > * {
  flex: 0 0 20%;
  max-width: 20%; }

.row.row-6 > * {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.row.row-7 > * {
  flex: 0 0 14.28571%;
  max-width: 14.28571%; }

.row.row-8 > * {
  flex: 0 0 12.5%;
  max-width: 12.5%; }

.row.row-9 > * {
  flex: 0 0 11.11111%;
  max-width: 11.11111%; }

.row.row-10 > * {
  flex: 0 0 10%;
  max-width: 10%; }

.row.row-11 > * {
  flex: 0 0 9.09091%;
  max-width: 9.09091%; }

.row.row-12 > * {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

@media (min-width: 576px) {
  .row.row-sm-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-sm-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-sm-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-sm-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-sm-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-sm-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-sm-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-sm-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-sm-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-sm-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-sm-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-sm-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 768.98px) {
  .row.row-md-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-md-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-md-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-md-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-md-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-md-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-md-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-md-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-md-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-md-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-md-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-md-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1024.98px) {
  .row.row-lg-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-lg-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-lg-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-lg-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-lg-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-lg-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-lg-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-lg-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-lg-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-lg-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-lg-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-lg-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

@media (min-width: 1280px) {
  .row.row-xl-1 > * {
    flex: 0 0 100%;
    max-width: 100%; }
  .row.row-xl-2 > * {
    flex: 0 0 50%;
    max-width: 50%; }
  .row.row-xl-3 > * {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row.row-xl-4 > * {
    flex: 0 0 25%;
    max-width: 25%; }
  .row.row-xl-5 > * {
    flex: 0 0 20%;
    max-width: 20%; }
  .row.row-xl-6 > * {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .row.row-xl-7 > * {
    flex: 0 0 14.28571%;
    max-width: 14.28571%; }
  .row.row-xl-8 > * {
    flex: 0 0 12.5%;
    max-width: 12.5%; }
  .row.row-xl-9 > * {
    flex: 0 0 11.11111%;
    max-width: 11.11111%; }
  .row.row-xl-10 > * {
    flex: 0 0 10%;
    max-width: 10%; }
  .row.row-xl-11 > * {
    flex: 0 0 9.09091%;
    max-width: 9.09091%; }
  .row.row-xl-12 > * {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; } }

/************************
    COLS SIZES
*************************/
.col-1 {
  flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  flex: 0 0 100%;
  max-width: 100%; }

.off-0 {
  margin-left: 0; }

.off-1 {
  margin-left: 8.33333%; }

.off-2 {
  margin-left: 16.66667%; }

.off-3 {
  margin-left: 25%; }

.off-4 {
  margin-left: 33.33333%; }

.off-5 {
  margin-left: 41.66667%; }

.off-6 {
  margin-left: 50%; }

.off-7 {
  margin-left: 58.33333%; }

.off-8 {
  margin-left: 66.66667%; }

.off-9 {
  margin-left: 75%; }

.off-10 {
  margin-left: 83.33333%; }

.off-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768.98px) {
  .col-md-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1024.98px) {
  .col-lg-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1280px) {
  .col-xl-1 {
    flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

body, html {
  line-height: 1;
  font-family: 'Lexend', sans-serif;
  color: #333;
  font-size: 3.2vw; }
  @media (min-width: 768px) {
    body, html {
      font-size: 1.5vw; } }
  @media (min-width: 1024px) {
    body, html {
      font-size: 14px; } }
  @media (min-width: 1600px) {
    body, html {
      font-size: 16px; } }

*, *:before, *:after {
  box-sizing: border-box;
  outline: none;
  -webkit-font-smoothing: antialiased; }

a {
  text-decoration: none; }

.fa-exclamation-triangle {
  line-height: 2.5;
  color: #ff3333;
  font-weight: 400;
  font-family: 'Anek Latin', sans-serif; }
  .fa-exclamation-triangle:before {
    font-family: 'Font Awesome 5 Pro'; }

.frm-btn-reset {
  display: none !important; }

.frm-btnwrap .label {
  display: none; }

.fancybox-close-small {
  width: auto; }
  .fancybox-close-small svg {
    width: 3rem;
    height: 3rem; }
    .fancybox-close-small svg path {
      fill: #00CE7C; }

.fancybox-container {
  z-index: 4999; }

.fixed-stuff {
  position: fixed;
  z-index: 50;
  bottom: 3.4375rem;
  right: 1.25rem; }
  .fixed-stuff ul li:nth-child(n + 2) {
    margin-top: 0.625rem; }
  .fixed-stuff ul li .btn-circle {
    transition: all 0.3s ease-in-out; }
  .fixed-stuff ul li:hover .btn-circle {
    background-color: #00CE7C; }
  .fixed-stuff ul li.has-tooltip {
    position: relative; }
    .fixed-stuff ul li.has-tooltip .btn-circle {
      position: relative;
      z-index: 2; }
    .fixed-stuff ul li.has-tooltip .tooltip {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #211261;
      color: #fff;
      border-radius: 999px;
      z-index: 1;
      padding: 0;
      overflow: hidden;
      opacity: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out; }
    .fixed-stuff ul li.has-tooltip:hover .tooltip {
      opacity: 1;
      pointer-events: all;
      padding: 0 5rem 0 1.875rem; }

#registerForm {
  width: 58.125rem; }

.footer-top {
  padding: 5.625rem 0 5rem; }
  @media (max-width: 1023px) {
    .footer-top {
      padding: 2.5rem 0; } }

.footer-item {
  display: flex;
  flex-direction: column;
  gap: 1rem; }
  .footer-item h3 {
    color: #211261; }
  .footer-item .cta-link {
    gap: 0.625rem; }
    .footer-item .cta-link em {
      width: 3.125rem;
      height: 3.125rem;
      background-color: #211261;
      color: #fff; }
    .footer-item .cta-link strong {
      flex-direction: column;
      align-items: flex-start;
      display: flex; }

.footer-worktime ul {
  display: flex;
  flex-direction: column;
  gap: 1rem; }
  .footer-worktime ul li {
    display: flex;
    flex-direction: column;
    gap: 0.375rem; }
    .footer-worktime ul li span, .footer-worktime ul li strong {
      color: #211261; }

.footer-links ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem; }

.footer-subscription .subscription-title {
  display: flex;
  flex-direction: column;
  gap: 1rem; }

.subscribefrm {
  position: relative; }
  .subscribefrm button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #00CE7C;
    font-size: 1.5rem;
    position: absolute;
    right: 1.25rem; }
  .subscribefrm input {
    width: 100%;
    height: 3rem;
    border-radius: 1.5rem;
    box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
    border: 0;
    padding: 0.75rem 3.75rem 0.75rem 1.25rem; }

.social-link {
  margin-top: 1.875rem; }
  .social-link ul {
    display: flex;
    gap: 0.625rem; }
    .social-link ul li a {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      color: #211261; }

.footer-bottom {
  padding: 1.875rem 0;
  color: #666666;
  border-top: 1px solid #e7e7e7; }
  @media (max-width: 767px) {
    .footer-bottom {
      text-align: center; } }

.homepage .global-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99; }
  @media (max-width: 1023px) {
    .homepage .global-header {
      position: relative; } }

.global-header {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.8) 100%);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 11; }

.header-top {
  padding: 0.9375rem 0;
  position: relative; }

@media (max-width: 1023px) {
  .logo img {
    height: 40px; } }

@media (min-width: 1024px) {
  .search-wrapper .container {
    max-width: none;
    padding: 0; } }

@media (max-width: 1023px) {
  .search-wrapper {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 0.9375rem 0;
    display: none;
    background-color: rgba(255, 255, 255, 0.75);
    z-index: 1; } }

.searchbox {
  position: relative; }
  .searchbox input {
    width: 100%;
    height: 3rem;
    border-radius: 100px;
    padding: 0.75rem 1.25rem 0.75rem 3.125rem;
    border: 1px solid #00CE7C; }
    .searchbox input::placeholder {
      color: #666666; }
  .searchbox button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    position: absolute;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.25rem; }
    .searchbox button em {
      color: #00CE7C; }

.header-util {
  display: flex;
  gap: 1.875rem;
  justify-content: flex-end;
  line-height: 1.25rem; }
  @media (max-width: 1599px) {
    .header-util {
      gap: 0.9375rem; } }
  @media (max-width: 1023px) {
    .header-util {
      gap: 0.625rem; } }

.header-util-divider {
  border-left: 1px solid #e7e7e7;
  height: 2.75rem; }
  @media (max-width: 1023px) {
    .header-util-divider {
      display: none; } }

.cta-link {
  display: flex;
  gap: 1rem;
  align-items: center;
  color: #211261; }
  @media (max-width: 1599px) {
    .cta-link {
      gap: 0.5rem; } }
  .cta-link em {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 999999px;
    border: 1px solid #211261;
    font-size: 1.25rem; }
  .cta-link strong {
    flex: 1;
    text-transform: uppercase; }
    @media (min-width: 1280px) {
      .cta-link strong {
        align-self: center;
        display: flex;
        align-items: center;
        gap: 0.5rem; } }
    .cta-link strong span {
      color: #00CE7C; }
      @media (max-width: 1279px) {
        .cta-link strong span {
          display: block; } }

.language.dropdown {
  display: flex;
  align-items: center;
  height: 100%;
  font-size: 0.875rem;
  line-height: 1.28571; }
  @media (max-width: 767px) {
    .language.dropdown {
      font-size: clamp(12px, 0.875rem, 14px); } }
  @media (max-width: 1023px) {
    .language.dropdown {
      font-size: 1.25rem;
      line-height: 1.4; } }
  @media (max-width: 1023px) and (max-width: 767px) {
    .language.dropdown {
      font-size: clamp(12px, 1.25rem, 20px); } }
  .language.dropdown .current {
    color: #999999;
    display: flex;
    gap: 0.25rem;
    align-items: center; }
    .language.dropdown .current .text {
      color: #211261; }
      @media (max-width: 1023px) {
        .language.dropdown .current .text {
          display: none; } }
    @media (max-width: 1023px) {
      .language.dropdown .current .fa-chevron-down {
        display: none; } }
    @media (max-width: 1023px) {
      .language.dropdown .current {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        height: 3rem;
        font-size: 1.5rem;
        line-height: 1;
        background-color: #999999;
        color: #fff; } }
  .language.dropdown .dropdown-content {
    top: 100%;
    min-width: auto; }
    .language.dropdown .dropdown-content ul {
      padding: 0.75rem 0.9375rem; }

@media (min-width: 1024px) {
  .menu ul {
    display: flex;
    gap: 0.625rem; } }

@media (max-width: 1023px) {
  .menu ul li:nth-child(n + 2) {
    margin-top: 4vw; } }

.menu ul li a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 1.5625rem;
  min-height: 3.125rem;
  font-size: 1.125rem;
  line-height: 1.22222;
  color: #211261;
  position: relative;
  z-index: 1; }
  @media (max-width: 767px) {
    .menu ul li a {
      font-size: clamp(12px, 1.125rem, 18px); } }
  @media (min-width: 1024px) {
    .menu ul li a span {
      display: none; } }
  @media (max-width: 1279px) {
    .menu ul li a {
      font-size: 0.8125rem; } }
  @media (max-width: 1023px) {
    .menu ul li a {
      font-size: 5.75vw;
      display: flex;
      min-height: 12vw;
      text-align: left;
      justify-content: flex-start;
      color: #fff;
      padding: 0 5vw; }
      .menu ul li a em {
        display: none; } }
  .menu ul li a:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    right: 5px;
    bottom: 5px;
    left: 5px;
    background-color: #00CE7C;
    border-radius: 1.5625rem 1.5625rem 0px 1.5625rem;
    background-clip: padding-box;
    z-index: -1;
    opacity: 0; }
    .menu ul li.active a:before {
      opacity: 1; }
    @media (max-width: 1023px) {
      .menu ul li a:before {
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 6vw 6vw 0px 6vw;
        background-clip: padding-box; } }

.btn-toggle {
  display: none; }
  .btn-toggle.search-toggle {
    background-color: #666666;
    color: #fff; }
  .btn-toggle.menu-toggle {
    background-color: #00CE7C;
    color: #fff; }
  @media (max-width: 1023px) {
    .btn-toggle {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 3rem;
      font-size: 1.5rem;
      line-height: 1; } }

.mobile-menu {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
  display: none;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px); }
  .mobile-menu .mobile-menu-overlay {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%; }
  .mobile-menu .mobile-menu-wrapper {
    z-index: 2;
    height: 100%;
    width: 100%;
    position: relative;
    transform: translateY(-100%);
    transition: 0.3s all;
    pointer-events: none; }
    .mobile-menu.is-open .mobile-menu-wrapper {
      transform: none; }
  .mobile-menu .mobile-menu-container {
    position: absolute;
    pointer-events: all;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 88vh;
    width: 94vw;
    border-radius: 10vw 10vw 0px 10vw;
    background-clip: padding-box;
    background: linear-gradient(180deg, #211261 50%, #2e1493 100%);
    padding: 8vw 6vw;
    overflow: auto; }
  .mobile-menu .logo-w {
    margin-bottom: 8vw; }

.search-page {
  padding: 3.75rem 0; }
  @media (max-width: 1023px) {
    .search-page {
      padding: 2.5rem 0; } }
  .search-page h1 {
    margin-bottom: 1.25rem;
    font-size: 2.5rem;
    line-height: 1.2;
    font-weight: 700;
    color: #333333; }
    @media (max-width: 767px) {
      .search-page h1 {
        font-size: clamp(12px, 2.5rem, 40px); } }
    .search-page h1.center {
      text-align: center; }
  .search-page .btn-reindex {
    display: none; }
  .search-page .searchcontrols .form-inline {
    position: relative; }
    .search-page .searchcontrols .form-inline:before {
      position: absolute;
      content: '\f002';
      font-family: "Font Awesome 6 Pro";
      top: 0;
      right: 0.625rem;
      z-index: 1;
      font-size: 1.5rem;
      color: #00CE7C;
      width: 3.75rem;
      height: 3.75rem;
      display: flex;
      align-items: center;
      justify-content: center; }
  .search-page .searchcontrols .frm-btn, .search-page .searchcontrols input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0.625rem;
    background-color: rgba(0, 0, 0, 0);
    z-index: 2;
    width: 3.75rem;
    height: 3.75rem;
    min-width: auto; }
  .search-page input[type="text"] {
    width: 100%;
    height: 100%;
    padding-left: 1.25rem;
    padding-right: 3.375rem;
    border: 1px solid #dfe1e5;
    background-color: #fff;
    color: #333;
    font-size: .875rem;
    font-weight: 400;
    height: 3.75rem !important;
    border-radius: 1.875rem !important;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0);
    transition: 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    outline: none; }
    .search-page input[type="text"]:focus {
      box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
      border-color: rgba(223, 225, 229, 0); }
  .search-page input[type="submit"] {
    border-radius: 2px;
    user-select: none;
    background-color: rgba(0, 0, 0, 0);
    border: 0;
    box-shadow: none;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    outline: none;
    width: 2.75rem;
    height: 2.75rem;
    border-radius: 50%;
    font-size: 0; }
  .search-page .text-danger {
    margin-top: 1.25rem;
    font-size: 0.875rem;
    color: #333;
    font-style: italic;
    font-weight: 600; }
  .search-page .searchresultsummary {
    margin-bottom: 20px; }
  .search-page .searchresults {
    margin-top: 1.875rem; }
    .search-page .searchresults .modulepager:first-child {
      display: none; }
  .search-page .searchresultlist {
    margin-bottom: 1.875rem; }
  .search-page .searchresult {
    margin-bottom: 1.875rem;
    box-shadow: 0 1px 6px rgba(32, 33, 36, 0.28);
    padding: 1rem;
    border-radius: 0.5rem; }
    .search-page .searchresult:last-child {
      margin-bottom: 0; }
    .search-page .searchresult h3 {
      font-size: 1.125rem;
      line-height: 1.33;
      font-weight: 400;
      margin-bottom: 0.625rem; }
      .search-page .searchresult h3 a {
        color: #1A0DAB;
        text-decoration: none;
        font-weight: 500; }
        .search-page .searchresult h3 a:hover {
          text-decoration: underline; }
    .search-page .searchresult .searchresultdesc {
      color: #545454;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small;
      font-weight: 400;
      margin-bottom: 0.625rem; }
    .search-page .searchresult .searchterm {
      color: #6a6a6a;
      font-weight: bold;
      font-style: normal;
      line-height: 1.54;
      word-wrap: break-word;
      font-size: small; }
    .search-page .searchresult hr {
      display: none !important; }

.AspNet-TreeView {
  line-height: 1.5; }
  .AspNet-TreeView > ul {
    columns: 3 auto;
    margin: 10px 0 0;
    padding-bottom: 25px; }
    .AspNet-TreeView > ul > .AspNet-TreeView-Root {
      margin-bottom: 26px;
      break-inside: avoid-column; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > a {
        display: inline-block;
        font-size: 20px;
        font-weight: 600;
        padding-bottom: 7px; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root .AspNet-TreeView-Collapse {
        display: none; }
      .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li {
        padding-bottom: 7px;
        font-size: 18px;
        color: #06c; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li > a {
          font-weight: 500;
          display: inline-block; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > a {
          margin-bottom: 7px; }
        .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul {
          list-style: disc;
          padding-left: 1.25em; }
          .AspNet-TreeView > ul > .AspNet-TreeView-Root > ul > li.AspNet-TreeView-Parent > ul > li {
            font-size: 16px;
            margin-bottom: 7px; }
  @media screen and (max-width: 768.98px) {
    .AspNet-TreeView > ul {
      column-count: 1; } }

.sitemap {
  padding: 2.5rem 0; }
  .sitemap a:hover {
    text-decoration: underline; }

.sitemap-heading {
  height: 3.25rem;
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  margin-bottom: 2rem; }
  .sitemap-heading h1 {
    font-weight: 600;
    font-size: 2rem;
    line-height: 1.5; }

.breadcrumb-wrapper {
  background-color: #fff;
  color: #999999;
  border-bottom: 1px solid #e7e7e7; }
  @media (max-width: 1023px) {
    .breadcrumb-wrapper {
      display: none; } }
  .breadcrumb-wrapper .breadcrumb {
    padding: 0.5rem 0;
    display: flex;
    align-items: center; }
    .breadcrumb-wrapper .breadcrumb li a {
      font-size: 0.875rem;
      line-height: 1.42857;
      display: flex;
      align-items: center; }
      @media (max-width: 767px) {
        .breadcrumb-wrapper .breadcrumb li a {
          font-size: clamp(12px, 0.875rem, 14px); } }
    .breadcrumb-wrapper .breadcrumb li + li {
      padding-left: 0.75rem;
      display: flex;
      align-items: center; }
      .breadcrumb-wrapper .breadcrumb li + li::before {
        content: "\f054";
        font-family: 'Font Awesome 6 Pro';
        margin-right: 0.75rem;
        font-weight: 400;
        font-size: 0.875rem;
        position: relative;
        font-weight: 300; }
    .breadcrumb-wrapper .breadcrumb li:first-child a {
      font-size: 0; }
      .breadcrumb-wrapper .breadcrumb li:first-child a:before {
        content: '\f015';
        font-family: 'Font Awesome 6 Pro';
        font-size: 1rem;
        line-height: 1.125;
        font-weight: 300; }
        @media (max-width: 767px) {
          .breadcrumb-wrapper .breadcrumb li:first-child a:before {
            font-size: clamp(12px, 1rem, 16px); } }

.contact-info > * + * {
  margin-top: 0.5rem; }

.contact-info .item {
  display: flex; }
  .contact-info .item .icon {
    height: 3.75rem;
    display: flex;
    max-width: 5rem;
    flex: 0 0 5rem;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: #00CE7C;
    color: #fff;
    font-size: 1.875rem; }
  .contact-info .item .content {
    flex: 1; }
  .contact-info .item .title {
    height: 3.75rem;
    display: flex;
    align-items: center;
    padding: 0 1rem;
    background-color: #fff; }
    .contact-info .item .title.background-blue {
      background-color: #211261;
      color: #fff; }
  .contact-info .item .brief {
    padding: 0.5rem 1rem 1.25rem;
    background-color: #fff;
    font-size: 1.125rem;
    line-height: 1.33333; }
    @media (max-width: 767px) {
      .contact-info .item .brief {
        font-size: clamp(12px, 1.125rem, 18px); } }
    .contact-info .item .brief ul > * + * {
      margin-top: 1rem; }
    .contact-info .item .brief ul li {
      display: flex; }
      .contact-info .item .brief ul li span {
        font-size: 1rem;
        margin-right: 0.75rem;
        position: relative;
        top: 0.25rem;
        color: #211261; }

.contact-form .row {
  margin-left: -1.25rem;
  margin-bottom: -1.25rem; }
  .contact-form .row > * {
    padding-left: 1.25rem;
    padding-bottom: 1.25rem; }

.contact-form .form-group input[type="text"], .contact-form .form-group input[type="date"], .contact-form .form-group select, .contact-form .form-group textarea {
  border: 1px solid #e5e5e5; }

.contact-form .frm-btnwrap {
  margin-top: 1.25rem; }

.contact-form .frm-btn input {
  border-radius: 999px !important; }

.contact-section {
  position: relative;
  z-index: 1; }
  @media (min-width: 1024px) {
    .contact-section::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 53.90625%;
      height: 100%;
      background-color: #fff;
      z-index: -1; } }

.news-horizontal-item {
  display: flex;
  border-radius: 1.25rem;
  overflow: hidden;
  background-color: #fff;
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.08); }
  .news-horizontal-item:nth-child(n + 2) {
    margin-top: 1.875rem; }
  .news-horizontal-item .news-img {
    max-width: 61.29032%;
    flex: 0 0 61.29032%;
    width: 100%; }
    .news-horizontal-item .news-img figure {
      height: 100%;
      padding-top: 56.14035%;
      display: block;
      position: relative; }
      .news-horizontal-item .news-img figure img, .news-horizontal-item .news-img figure iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; }
      .news-horizontal-item .news-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
    @media (max-width: 767px) {
      .news-horizontal-item .news-img {
        max-width: 33.33333%;
        flex: 0 0 33.33333%;
        width: 100%; }
        .news-horizontal-item .news-img figure {
          min-height: 7.5rem; } }
  .news-horizontal-item .news-caption {
    padding: 2rem 2.25rem; }
    @media (max-width: 767px) {
      .news-horizontal-item .news-caption {
        padding: 0.75rem 1rem; } }
    .news-horizontal-item .news-caption > * + * {
      margin-top: 1rem; }
      @media (max-width: 767px) {
        .news-horizontal-item .news-caption > * + * {
          margin-top: 0.5rem; } }
  .news-horizontal-item .news-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical; }
  .news-horizontal-item .news-brief {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical; }
    @media (max-width: 1023px) {
      .news-horizontal-item .news-brief {
        display: none; } }

.news-item {
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 0.625rem; }
  .news-item .news-img figure {
    padding-top: 100%;
    display: block;
    position: relative; }
    .news-item .news-img figure img, .news-item .news-img figure iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .news-item .news-caption {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1.25rem;
    transition: all 0.3s ease-in-out;
    background-color: rgba(0, 0, 0, 0.33);
    color: #fff;
    display: flex;
    flex-direction: column;
    gap: 0.625rem;
    align-items: flex-start; }
  .news-item .news-cate {
    display: inline-block;
    padding: 0.25rem 0.5rem;
    border-radius: 0.3125rem;
    background-color: #fff;
    color: #333; }
  .news-item .news-title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 3rem; }
  @media (hover: hover) and (pointer: fine) {
    .news-item:hover .news-caption {
      padding-bottom: 2.5rem;
      background-color: rgba(0, 206, 124, 0.9); } }

@media (min-width: 1024px) {
  .support-side-nav {
    padding-right: 1.875rem; } }

.support-side-nav .heading-5 {
  border-bottom: 1px solid #e7e7e7; }

.support-side-nav ul li {
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 1px solid #e7e7e7; }
  .support-side-nav ul li a {
    font-size: 1rem;
    line-height: 1.25;
    color: #666666; }
    @media (max-width: 767px) {
      .support-side-nav ul li a {
        font-size: clamp(12px, 1rem, 16px); } }
    .support-side-nav ul li.active a {
      color: #00CE7C; }

.support-content-wrap {
  padding: 3rem 3.75rem;
  background-color: #EEF5F7; }
  @media (max-width: 1023px) {
    .support-content-wrap {
      padding: 1.25rem; } }
  .support-content-wrap .step-tab {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1; }
    @media (min-width: 1024px) {
      .support-content-wrap .step-tab::after {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        position: absolute;
        background: linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.414969) 5%, #D9D9D9 49.8%, rgba(217, 217, 217, 0.414969) 94.65%, rgba(217, 217, 217, 0) 100%);
        height: 1px;
        left: -3.75rem;
        right: -3.75rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%); } }
    .support-content-wrap .step-tab li a {
      width: 3.75rem;
      height: 3.75rem;
      border-radius: 999999px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #fff;
      font-size: 0.875rem;
      line-height: 1.42857;
      color: #211261; }
      @media (max-width: 767px) {
        .support-content-wrap .step-tab li a {
          font-size: clamp(12px, 0.875rem, 14px); } }
      .support-content-wrap .step-tab li.active a {
        background-color: #00CE7C;
        color: #fff; }
      @media (max-width: 1023px) {
        .support-content-wrap .step-tab li a {
          width: 3.25rem;
          height: 3.25rem;
          border-radius: 999999px;
          font-size: 0.75rem; } }
  .support-content-wrap .step-table table {
    width: 100%;
    border: 1px solid #e7e7e7;
    background-color: #fff; }
    .support-content-wrap .step-table table td, .support-content-wrap .step-table table th {
      padding: 0.75rem 1.75rem;
      text-align: left;
      border-right: 1px solid #e7e7e7;
      font-size: 1rem;
      line-height: 1.5; }
      @media (max-width: 767px) {
        .support-content-wrap .step-table table td, .support-content-wrap .step-table table th {
          font-size: clamp(12px, 1rem, 16px); } }
      @media (max-width: 1023px) {
        .support-content-wrap .step-table table td, .support-content-wrap .step-table table th {
          padding: 0.75rem 1rem; } }
    .support-content-wrap .step-table table thead tr th {
      background-color: #211261;
      color: #fff; }

.about-1 {
  position: relative; }
  @media (min-width: 1024px) {
    .about-1 {
      margin: 0 0.5rem 0.5rem; } }
  @media (max-width: 1023px) {
    .about-1 .img-wrap figure {
      padding-top: 66.6667%;
      display: block;
      position: relative; }
      .about-1 .img-wrap figure img, .about-1 .img-wrap figure iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; } }
  @media (min-width: 1024px) {
    .about-1 .img-wrap figure {
      height: 38.125rem; }
      .about-1 .img-wrap figure img, .about-1 .img-wrap figure iframe {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  .about-1 .content-wrap {
    color: #fff;
    pointer-events: none; }
    @media (max-width: 1023px) {
      .about-1 .content-wrap {
        padding: 2rem 0;
        background-color: #211261; } }
    @media (min-width: 1024px) {
      .about-1 .content-wrap {
        position: absolute;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        width: 100%;
        z-index: 2; } }
    .about-1 .content-wrap .content {
      pointer-events: all; }
      @media (min-width: 1024px) {
        .about-1 .content-wrap .content {
          padding-right: 5rem; } }
  .about-1 .content-bg {
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    width: 50%;
    border-right: 0.5rem solid #fff; }
    @media (max-width: 1023px) {
      .about-1 .content-bg {
        display: none; } }
    .about-1 .content-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about-1 .swiper-navigation .swiper-btn {
    display: flex !important;
    margin: 0 1rem;
    background-color: #211261;
    color: #fff; }
    .about-1 .swiper-navigation .swiper-btn.swiper-prev {
      right: auto;
      left: 0; }
    .about-1 .swiper-navigation .swiper-btn.swiper-next {
      left: auto;
      right: 0; }

.about-gallery-slider .swiper-pagination {
  display: flex;
  gap: 0.625rem;
  justify-content: center; }
  .about-gallery-slider .swiper-pagination .swiper-pagination-bullet {
    border-radius: 0;
    width: 1.25rem;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.5);
    transform: none;
    opacity: 1;
    transition: 0.3s all; }
    .about-gallery-slider .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
      width: 3.75rem;
      background-color: white; }

section[class*="about-"] {
  scroll-margin-top: 13.0625rem;
  scroll-snap-type: y mandatory; }

.about-2 {
  position: relative; }
  @media (min-width: 1024px) {
    .about-2 {
      margin: 0 0.5rem 0.5rem; } }
  @media (min-width: 1024px) {
    .about-2 .img-wrap .img {
      border-right: 0.5rem solid #fff; } }
  @media (min-width: 1024px) {
    .about-2 .img-wrap figure {
      height: 38.125rem; }
      .about-2 .img-wrap figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (max-width: 1023px) {
    .about-2 .content-wrap {
      padding: 2rem 0;
      background-color: #EEF5F7; } }
  @media (min-width: 1024px) {
    .about-2 .content-wrap {
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 100%;
      z-index: 2; } }
  @media (min-width: 1024px) {
    .about-2 .content {
      padding-left: 5rem; } }
  .about-2 .content-bg {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 1;
    width: 50%; }
    @media (max-width: 1023px) {
      .about-2 .content-bg {
        display: none; } }
    .about-2 .content-bg img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .about-2 .item-list ul {
    line-height: 1.333333; }
    .about-2 .item-list ul > * + * {
      margin-top: 0.5rem; }
    .about-2 .item-list ul li {
      display: flex;
      padding: 1.25rem;
      background-color: #fff;
      color: #211261;
      align-items: center;
      font-weight: 300;
      border-left: 1px solid #00CE7C; }
      .about-2 .item-list ul li em {
        font-size: 1.5rem;
        max-width: 1.5rem;
        flex: 0 0 1.5rem;
        width: 100%;
        margin-right: 1.5rem; }

.about-bg-1 {
  padding-top: 9.5rem;
  background-image: linear-gradient(180deg, rgba(238, 245, 247, 0) 0%, #EEF5F7 100%);
  background-position: bottom;
  position: relative;
  overflow: hidden; }
  .about-bg-1::before {
    position: absolute;
    content: '';
    border-bottom: 2rem solid #74B696;
    width: 100%;
    z-index: 2;
    bottom: 0;
    left: 0; }
  @media (max-width: 1023px) {
    .about-bg-1 {
      padding-top: 2.5rem; } }

.about-3 .img figure {
  border-radius: 2rem;
  overflow: hidden;
  height: 100%; }
  .about-3 .img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }

.about-3 .item-list > * + * {
  margin-top: 2rem; }
  @media (max-width: 1023px) {
    .about-3 .item-list > * + * {
      margin-top: 1.25rem; } }

.about-3 .item-list .item {
  padding: 3rem 3.75rem;
  background-color: #EEF5F7;
  border-radius: 2rem; }
  @media (min-width: 1024px) {
    .about-3 .item-list .item {
      height: calc(50% - 1rem); } }
  @media (max-width: 1023px) {
    .about-3 .item-list .item {
      padding: 2rem; } }
  .about-3 .item-list .item > * + * {
    margin-top: 1.25rem; }
  .about-3 .item-list .item .icon span {
    font-size: 2rem;
    color: #00CE7C; }

.about-5 {
  margin-top: 5rem; }

.history-year-dot-slider {
  position: relative; }
  .history-year-dot-slider::before {
    content: '';
    height: 1px;
    background: #e7e7e7;
    position: absolute;
    top: 1.25rem;
    width: 100%; }
  @media (min-width: 1024px) {
    .history-year-dot-slider .swiper-container {
      width: 57.5rem;
      margin: 0 auto; } }
  .history-year-dot-slider .swiper-slide .year-item {
    cursor: pointer;
    height: 4.25rem; }
    .history-year-dot-slider .swiper-slide .year-item .dot {
      width: 2.5rem;
      height: 2.5rem;
      border-radius: 999999px;
      position: relative;
      margin: 0 auto; }
      .history-year-dot-slider .swiper-slide .year-item .dot span {
        position: absolute;
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 999999px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #211261;
        transition: all 0.3s ease-in-out; }
        .history-year-dot-slider .swiper-slide.swiper-slide-thumb-active .year-item .dot span {
          background-color: #00CE7C;
          width: 2.5rem;
          height: 2.5rem;
          border-radius: 999999px; }
    .history-year-dot-slider .swiper-slide .year-item .text {
      text-align: center;
      margin-top: 0.25rem;
      transition: all 0.3s ease-in-out; }
      .history-year-dot-slider .swiper-slide.swiper-slide-thumb-active .year-item .text {
        font-size: 1.5rem;
        font-weight: 700;
        color: #00CE7C; }

.history-year-content-slider .swiper-navigation {
  margin: 3.75rem -0.25rem 0; }
  @media (max-width: 1023px) {
    .history-year-content-slider .swiper-navigation {
      display: none; } }
  .history-year-content-slider .swiper-navigation .swiper-btn {
    position: static;
    transform: none;
    border-color: #fff;
    color: #fff;
    margin: 0 0.25rem; }

@media (max-width: 1023px) {
  .history-year-content-slider {
    padding: 2.5rem 0; } }

@media (min-width: 1024px) {
  .history-year-content-slider {
    padding-left: 3.75rem; } }

.about-5-container {
  background-color: #211261;
  color: #fff;
  position: relative; }
  @media (min-width: 1024px) {
    .about-5-container .content-wrap {
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; } }
  @media (min-width: 1024px) {
    .about-5-container .img-wrap figure {
      height: 36.25rem; }
      .about-5-container .img-wrap figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }

.about-4 {
  margin-top: 4.5rem; }
  @media (max-width: 1023px) {
    .about-4 {
      margin-top: 2.5rem; } }
  .about-4 .img {
    margin-bottom: 0;
    position: relative;
    z-index: 1; }
  .about-4 .item-list {
    border-bottom: 1px solid #e7e7e7; }
    .about-4 .item-list .item {
      padding: 1.25rem 0;
      border-top: 1px solid #e7e7e7; }
      .about-4 .item-list .item:nth-child(1n) .title {
        color: #74B696; }
      .about-4 .item-list .item:nth-child(2n) .title {
        color: #7999B6; }
      .about-4 .item-list .item:nth-child(3n) .title {
        color: #ACC58C; }

.about-6 .staff-item .staff-position ul li {
  background-color: #fff; }

.about-7 ul.tabs-nav {
  padding: 0.25rem;
  background-color: #F5F5F5;
  display: inline-flex;
  border-radius: 999px;
  overflow: auto; }
  @media (max-width: 575px) {
    .about-7 ul.tabs-nav {
      width: 100%; } }
  .about-7 ul.tabs-nav li a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 2rem;
    border-radius: 999px;
    padding: 0 0.75rem;
    white-space: nowrap;
    color: #666666; }
    .about-7 ul.tabs-nav li.active a {
      background-color: #fff;
      color: #211261;
      font-weight: 700; }

.about-7 .gallery-item .img-wrap .img {
  display: block;
  border-radius: 0.75rem;
  overflow: hidden; }
  .about-7 .gallery-item .img-wrap .img figure {
    padding-top: 67.6259%;
    display: block;
    position: relative; }
    .about-7 .gallery-item .img-wrap .img figure img, .about-7 .gallery-item .img-wrap .img figure iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }

.about-7 .gallery-item .row {
  margin-left: -1.25rem;
  margin-bottom: -1.25rem; }
  .about-7 .gallery-item .row > * {
    padding-left: 1.25rem;
    padding-bottom: 1.25rem; }
  .about-7 .gallery-item .row .col-lg-6:nth-child(1) .img-wrap .img figure {
    padding-top: 69.11765%;
    display: block;
    position: relative; }
    .about-7 .gallery-item .row .col-lg-6:nth-child(1) .img-wrap .img figure img, .about-7 .gallery-item .row .col-lg-6:nth-child(1) .img-wrap .img figure iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .about-7 .gallery-item .row .col-lg-6:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -1.25rem;
    padding-left: 0; }
    .about-7 .gallery-item .row .col-lg-6:nth-child(2) > * {
      flex: 0 0 50%;
      max-width: 50%;
      padding-left: 1.25rem;
      padding-bottom: 1.25rem; }

.about-sub-nav {
  padding: 0.75rem 0;
  background-color: #fff; }
  .about-sub-nav .sub-nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.125rem;
    line-height: 1.33333;
    color: #666666;
    font-weight: 300;
    letter-spacing: -0.04em;
    overflow-x: auto;
    white-space: nowrap;
    scroll-snap-type: x mandatory; }
    @media (max-width: 767px) {
      .about-sub-nav .sub-nav {
        font-size: clamp(12px, 1.125rem, 18px); } }
    @media (max-width: 1023px) {
      .about-sub-nav .sub-nav > * + * {
        margin-left: 1.25rem; } }
    .about-sub-nav .sub-nav li {
      scroll-snap-align: center; }
      .about-sub-nav .sub-nav li.active a {
        color: #00CE7C; }

.department-detail-1 {
  position: relative;
  margin: 0 0.5rem; }
  @media (min-width: 1024px) {
    .department-detail-1 .img {
      border-right: 0.5rem solid #fff; } }
  @media (min-width: 1024px) {
    .department-detail-1 .img figure {
      height: 38.125rem; }
      .department-detail-1 .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (max-width: 1023px) {
    .department-detail-1 .content-wrap {
      padding: 2rem 0; } }
  @media (min-width: 1024px) {
    .department-detail-1 .content-wrap {
      position: absolute;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%; } }
  @media (min-width: 1024px) {
    .department-detail-1 .content {
      padding-left: 2.5rem; } }
  @media (min-width: 1280px) {
    .department-detail-1 .content {
      padding-left: 5.5rem; } }
  .department-detail-1 .desc > * + * {
    margin-top: 1rem; }

.department-detail-2 .item .img figure {
  padding-top: 56.66667%;
  display: block;
  position: relative; }
  .department-detail-2 .item .img figure img, .department-detail-2 .item .img figure iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover; }

.home-banner {
  position: relative; }
  @media (min-width: 1280px) {
    .home-banner .banner-item .banner-img figure {
      height: 100vh; } }
  .home-banner .banner-item .banner-img figure img {
    width: 100%;
    height: 100%;
    object-fit: cover; }
  .home-banner .swiper-pagination-wrap {
    position: absolute;
    right: 0;
    z-index: 2;
    bottom: 6.25rem;
    width: 100%; }
    @media (max-width: 1023px) {
      .home-banner .swiper-pagination-wrap {
        bottom: 1.875rem; } }
  .home-banner .swiper-pagination {
    position: static;
    display: flex;
    gap: 0.625rem;
    justify-content: flex-end; }
    @media (max-width: 1023px) {
      .home-banner .swiper-pagination {
        justify-content: center; } }
    .home-banner .swiper-pagination .swiper-pagination-bullet {
      border-radius: 0;
      width: 1.25rem;
      height: 4px;
      background-color: rgba(255, 255, 255, 0.5);
      transform: none;
      opacity: 1;
      transition: 0.3s all; }
      .home-banner .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 3.75rem;
        background-color: white; }

.request-form-wrapper {
  padding: 1.875rem 0; }
  @media (min-width: 1024px) {
    .request-form-wrapper {
      position: absolute;
      bottom: 2.5rem;
      width: 100%;
      left: 0;
      z-index: 2;
      padding: 0; } }

.request-form {
  padding: 1.25rem 1.5625rem;
  border-radius: 0.625rem;
  background-clip: padding-box;
  background-color: rgba(0, 206, 124, 0.75); }
  .request-form .form-row {
    display: flex;
    gap: 0.9375rem; }
    @media (max-width: 1023px) {
      .request-form .form-row {
        flex-wrap: wrap;
        justify-content: center; } }
  .request-form .form-group {
    flex: 1; }
    @media (max-width: 1023px) {
      .request-form .form-group {
        max-width: calc(50% - 7.5px);
        flex: 0 0 calc(50% - 7.5px);
        width: 100%; } }
    @media (max-width: 575px) {
      .request-form .form-group {
        max-width: calc(100% - 7.5px);
        flex: 0 0 calc(100% - 7.5px);
        width: 100%; } }
  .request-form .frm-btnwrap {
    margin-top: 0; }
  .request-form .frm-btn {
    width: 100%; }

.home-intro .item-list .row {
  margin-left: -1.5625rem;
  margin-bottom: -1.5625rem; }
  .home-intro .item-list .row > * {
    padding-left: 1.5625rem;
    padding-bottom: 1.5625rem; }
  @media (max-width: 575px) {
    .home-intro .item-list .row {
      margin-left: -0.625rem;
      margin-bottom: -0.625rem; }
      .home-intro .item-list .row > * {
        padding-left: 0.625rem;
        padding-bottom: 0.625rem; } }

.home-intro .item-list .item-col .item {
  transition: all 0.3s ease-in-out;
  aspect-ratio: 1;
  display: flex;
  flex-direction: column;
  gap: 0.9375rem;
  padding: 2.5rem;
  border-radius: 3.125rem;
  color: #fff; }
  @media (max-width: 1023px) {
    .home-intro .item-list .item-col .item {
      padding: 1.5rem;
      border-radius: 1.875rem; } }
  .home-intro .item-list .item-col .item .icon {
    font-size: 4rem;
    transition: all 0.3s ease-in-out; }
    .home-intro .item-list .item-col .item:hover .icon {
      transform: translateY(-10px); }

@media (max-width: 767px) {
  .home-intro .item-list .item-col .item {
    background-color: #74B696; }
  .home-intro .item-list .item-col:nth-child(4n+1) .item, .home-intro .item-list .item-col:nth-child(4n+4) .item {
    background-color: #ACC58C; } }

@media (min-width: 768px) {
  .home-intro .item-list .item-col:nth-child(odd) .item {
    background-color: #ACC58C; }
  .home-intro .item-list .item-col:nth-child(even) .item {
    background-color: #74B696; }
  .home-intro .item-list .item-col:nth-child(5n+3) .item {
    background-color: #7999B6; } }

.home-room {
  position: relative;
  background-color: #EEF5F6; }
  @media (min-width: 1024px) {
    .home-room {
      background-image: url(../img/home-bg-2.png);
      background-position: 100% 0;
      background-repeat: no-repeat;
      background-size: auto; } }
  @media (min-width: 1024px) {
    .home-room {
      height: 960px;
      display: flex; } }
  @media (min-width: 1024px) {
    .home-room .home-room-detail-wrap {
      width: 50%;
      display: flex;
      box-shadow: 15px 0px 0px 0px #00CE7C; } }
  .home-room .room-detail-item {
    display: none;
    width: 100%; }
    .home-room .room-detail-item:first-child {
      display: block; }
  @media (min-width: 1024px) {
    .home-room .room-detail-item-img {
      height: 100%; } }
  @media (max-width: 1023px) {
    .home-room .room-detail-item-img figure {
      padding-top: 56.25%;
      display: block;
      position: relative; }
      .home-room .room-detail-item-img figure img, .home-room .room-detail-item-img figure iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover; } }
  @media (min-width: 1024px) {
    .home-room .room-detail-item-img figure {
      height: 100%; }
      .home-room .room-detail-item-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }
  @media (min-width: 1024px) {
    .home-room .room-detail-item-content-wrap {
      position: absolute;
      width: 100%;
      bottom: 0;
      left: 0;
      pointer-events: none; } }
  .home-room .room-detail-item-content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    align-items: flex-start;
    padding: 2.5rem;
    background: rgba(245, 245, 245, 0.93);
    border-radius: 0.625rem 0px 0px 0px;
    background-clip: padding-box;
    pointer-events: all; }
    .home-room .room-detail-item-content .text-body-3, .home-room .room-detail-item-content .section-heading .section-desc, .section-heading .home-room .room-detail-item-content .section-desc {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical; }
  @media (min-width: 1024px) {
    .home-room .home-room-title-list-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      z-index: 2;
      display: flex; }
      .home-room .home-room-title-list-wrap .row {
        height: 100%; }
        .home-room .home-room-title-list-wrap .row > * {
          max-height: 100%; } }
  @media (min-width: 1024px) {
    .home-room .section-heading {
      padding: 2.8125rem 0 2.8125rem 2.5rem; } }
  .home-room .section-heading .heading-1 {
    font-size: 2.5rem; }
  .home-room .home-room-title-list-container {
    pointer-events: all; }
    @media (min-width: 1024px) {
      .home-room .home-room-title-list-container {
        display: flex;
        flex-direction: column;
        height: 100%; } }
  @media (min-width: 1024px) {
    .home-room .home-room-title-list {
      overflow: hidden;
      flex: 1;
      max-height: 100%; } }
  .home-room .home-room-title-list ul {
    display: flex;
    flex-direction: column;
    gap: 0.3125rem;
    height: 100%;
    overflow-y: auto;
    scrollbar-width: thin; }
    .home-room .home-room-title-list ul::-webkit-scrollbar {
      width: 4px;
      height: 4px; }
    .home-room .home-room-title-list ul::-webkit-scrollbar-thumb {
      background: #00CE7C; }
    .home-room .home-room-title-list ul::-webkit-scrollbar-track {
      background: #ccc; }
    @media (max-width: 1023px) {
      .home-room .home-room-title-list ul {
        gap: 0.9375rem; } }
    @media (min-width: 1024px) {
      .home-room .home-room-title-list ul {
        padding-left: 0.9375rem; } }
    .home-room .home-room-title-list ul li {
      flex: 1; }
      .home-room .home-room-title-list ul li .room-title {
        display: flex;
        min-height: 3.75rem;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        color: #211261;
        position: relative;
        transition: all 0.3s ease-in-out; }
        .home-room .home-room-title-list ul li .room-title:hover, .home-room .home-room-title-list ul li .room-title.active {
          background-color: #fff; }
        @media (max-width: 1023px) {
          .home-room .home-room-title-list ul li .room-title {
            padding: 0.9375rem 0; } }
        .home-room .home-room-title-list ul li .room-title .room-title-icon {
          padding-left: 2.5rem;
          padding-right: 0.625rem;
          max-width: 5rem;
          flex: 0 0 5rem;
          width: 100%;
          font-size: 1.5rem;
          z-index: 1;
          display: flex;
          align-items: center;
          min-height: 3.75rem;
          height: 100%;
          transition: all 0.3s ease-in-out; }
          .home-room .home-room-title-list ul li .room-title:hover .room-title-icon {
            color: #fff; }
          .home-room .home-room-title-list ul li .room-title.active .room-title-icon {
            color: #fff; }
          @media (max-width: 1023px) {
            .home-room .home-room-title-list ul li .room-title .room-title-icon {
              max-width: 3.75rem;
              flex: 0 0 3.75rem;
              width: 100%;
              padding: 0;
              justify-content: center; } }
          .home-room .home-room-title-list ul li .room-title .room-title-icon:before {
            content: "";
            display: block;
            position: absolute;
            width: 0;
            height: 100%;
            z-index: -1;
            transition: all 0.3s ease-in-out;
            background-color: #00CE7C;
            left: 0;
            top: 0;
            bottom: 0; }
            .home-room .home-room-title-list ul li .room-title:hover .room-title-icon:before {
              width: 5rem; }
              @media (max-width: 1023px) {
                .home-room .home-room-title-list ul li .room-title:hover .room-title-icon:before {
                  width: 3.75rem; } }
            .home-room .home-room-title-list ul li .room-title.active .room-title-icon:before {
              width: 5rem; }
              @media (max-width: 1023px) {
                .home-room .home-room-title-list ul li .room-title.active .room-title-icon:before {
                  width: 3.75rem; } }
        .home-room .home-room-title-list ul li .room-title .room-title-text {
          padding: 0 0.9375rem;
          display: flex;
          align-items: center;
          font-size: 1.125rem;
          line-height: 1.22222;
          flex: 1; }
          @media (max-width: 767px) {
            .home-room .home-room-title-list ul li .room-title .room-title-text {
              font-size: clamp(12px, 1.125rem, 18px); } }
          @media (max-width: 1023px) {
            .home-room .home-room-title-list ul li .room-title .room-title-text {
              padding-right: 3.75rem; }
              .home-room .home-room-title-list ul li .room-title .room-title-text:after {
                content: "";
                display: block;
                position: absolute;
                content: "";
                font-family: 'Font Awesome 6 Pro';
                right: 1.875rem;
                position: absolute;
                top: 50%;
                transform: translateY(-50%); }
                .home-room .home-room-title-list ul li .room-title.active .room-title-text:after {
                  content: "";
                  font-family: 'Font Awesome 6 Pro'; } }
      .home-room .home-room-title-list ul li .room-content-mobile {
        display: none; }
      .home-room .home-room-title-list ul li .room-detail-item-content {
        padding: 1.5rem;
        background-color: #fff; }

.home-service {
  overflow: hidden; }
  .home-service .section-heading {
    gap: 0.625rem; }

@media (min-width: 1280px) {
  .home-service-slider {
    padding-left: 1.25rem; } }

.home-service-slider .swiper-container {
  overflow: visible; }

.home-service-slider .swiper-slide {
  height: auto; }

.home-service-slider .swiper-scrollbar {
  height: 0.25rem;
  margin-top: 2.8125rem;
  background: #BEC8C5; }
  @media (max-width: 1023px) {
    .home-service-slider .swiper-scrollbar {
      display: none; } }
  .home-service-slider .swiper-scrollbar .swiper-scrollbar-drag {
    background-color: #00CE7C;
    cursor: grab; }

.home-service-slider .home-service-item {
  height: 100%; }
  @media (max-width: 1279px) {
    .home-service-slider .home-service-item {
      background: rgba(245, 245, 245, 0.93);
      border-radius: 0.625rem;
      background-clip: padding-box;
      overflow: hidden; } }
  @media (min-width: 1280px) {
    .home-service-slider .home-service-item {
      display: flex;
      justify-content: flex-end; } }
  @media (min-width: 1280px) {
    .home-service-slider .home-service-item .home-service-img {
      max-width: 80.86957%;
      flex: 0 0 80.86957%;
      width: 100%; } }
  .home-service-slider .home-service-item .home-service-img figure {
    overflow: hidden; }
    @media (max-width: 1279px) {
      .home-service-slider .home-service-item .home-service-img figure {
        aspect-ratio: 930 / 620; } }
    @media (min-width: 1280px) {
      .home-service-slider .home-service-item .home-service-img figure {
        height: 38.75rem;
        border-radius: 0.625rem;
        background-clip: padding-box; } }
    .home-service-slider .home-service-item .home-service-img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .home-service-slider .home-service-item .home-service-content {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
    padding: 1.5rem; }
    @media (min-width: 1280px) {
      .home-service-slider .home-service-item .home-service-content {
        background: rgba(245, 245, 245, 0.93);
        position: absolute;
        bottom: 0;
        left: 0;
        width: 43.47826%;
        padding: 2.5rem;
        transition: all 0.3s ease-in-out;
        border-radius: 2.5rem 2.5rem 0 2.5rem;
        background-clip: padding-box; }
        .home-service-slider .home-service-item:hover .home-service-content {
          background: #fff; } }
    @media (min-width: 1600px) {
      .home-service-slider .home-service-item .home-service-content {
        width: 34.78261%; } }
  .home-service-slider .home-service-item .home-service-brief ul {
    display: flex;
    flex-direction: column;
    gap: 0.625rem; }
    .home-service-slider .home-service-item .home-service-brief ul li {
      display: flex;
      gap: 1.25rem;
      align-items: center;
      padding: 0.625rem 0 1.25rem;
      border-bottom: 1px solid #BEC8C5; }
      .home-service-slider .home-service-item .home-service-brief ul li em {
        max-width: 32px;
        flex: 0 0 32px;
        width: 100%;
        font-size: 2rem;
        color: #00CE7C; }

.home-news-tabs .tab-nav {
  display: flex;
  gap: 0.5rem;
  width: fit-content;
  margin: 0 auto 3.75rem;
  justify-content: center;
  padding: 0.25rem;
  background-color: #F5F5F5;
  border-radius: 999px; }
  @media (max-width: 1023px) {
    .home-news-tabs .tab-nav {
      margin-bottom: 1.875rem; } }
  .home-news-tabs .tab-nav li a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: #666666;
    min-height: 2rem;
    padding: 0.4375rem 1.0625rem;
    border-radius: 1rem;
    transition: all 0.3s ease-in-out; }
    .home-news-tabs .tab-nav li.active a {
      background-color: #fff;
      box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05);
      color: #211261;
      font-weight: 700; }
    @media (hover: hover) and (pointer: fine) {
      .home-news-tabs .tab-nav li a:hover {
        color: #211261;
        background-color: #fff;
        box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05); } }

@media (min-width: 1024px) {
  .home-news-tabs .news-slider {
    max-width: 65.625rem;
    margin: 0 auto; } }

.home-facility {
  overflow: hidden; }

@media (min-width: 1024px) {
  .home-facility-slider .swiper-container {
    overflow: visible; } }

.home-facility-slider .home-facility-item {
  position: relative; }
  .home-facility-slider .home-facility-item .home-facility-img {
    border-radius: 0.625rem;
    background-clip: padding-box;
    overflow: hidden; }
    .home-facility-slider .home-facility-item .home-facility-img figure {
      aspect-ratio: 690 / 460; }
      .home-facility-slider .home-facility-item .home-facility-img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .home-facility-slider .home-facility-item .home-facility-caption {
    text-align: center; }
    @media (min-width: 1024px) {
      .home-facility-slider .home-facility-item .home-facility-caption {
        border-radius: 0.625rem;
        background-clip: padding-box;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 0.625rem;
        align-items: center;
        justify-content: center;
        background-color: rgba(0, 0, 0, 0.33);
        color: #fff;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none; }
        .home-facility-slider .home-facility-item:hover .home-facility-caption {
          opacity: 1; } }
    @media (min-width: 1600px) {
      .home-facility-slider .home-facility-item .home-facility-caption {
        padding: 0 7.5rem; } }
    .home-facility-slider .home-facility-item .home-facility-caption em {
      font-size: 4rem; }
      @media (max-width: 1023px) {
        .home-facility-slider .home-facility-item .home-facility-caption em {
          display: none; } }
    .home-facility-slider .home-facility-item .home-facility-caption .heading-4, .home-facility-slider .home-facility-item .home-facility-caption #couponRegisterForm .popup-form-wrap h2, #couponRegisterForm .popup-form-wrap .home-facility-slider .home-facility-item .home-facility-caption h2 {
      margin-top: 0.9375rem; }
      @media (max-width: 1023px) {
        .home-facility-slider .home-facility-item .home-facility-caption .heading-4, .home-facility-slider .home-facility-item .home-facility-caption #couponRegisterForm .popup-form-wrap h2, #couponRegisterForm .popup-form-wrap .home-facility-slider .home-facility-item .home-facility-caption h2 {
          font-size: 1.5rem; } }

.staff-item {
  display: flex;
  flex-direction: column;
  gap: 0.9375rem; }
  .staff-item .staff-item-img figure {
    border-radius: 0.625rem;
    background-clip: padding-box;
    overflow: hidden;
    aspect-ratio: 330 / 440; }
    .staff-item .staff-item-img figure img {
      width: 100%;
      height: 100%;
      object-fit: cover; }
  .staff-item .staff-item-caption {
    display: flex;
    flex-direction: column;
    gap: 0.9375rem; }
  .staff-item .staff-name > * {
    display: block; }
  .staff-item .staff-position ul {
    display: flex;
    flex-direction: column;
    gap: 0.25rem; }
    .staff-item .staff-position ul li {
      padding: 0.3125rem 0.9375rem;
      background-color: #EEF5F7;
      border-left: 2px solid #00CE7C;
      border-radius: 2px; }

.detail-meta {
  display: flex;
  align-items: center; }
  .detail-meta > * + * {
    margin-left: 1.25rem; }

.detail-tag span {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  background-color: #00CE7C;
  color: #211261;
  font-size: 0.75rem;
  line-height: 1.33333; }
  @media (max-width: 767px) {
    .detail-tag span {
      font-size: clamp(12px, 0.75rem, 12px); } }

.detail-date {
  font-size: 0.875rem;
  line-height: 1.42857;
  color: #999999; }
  @media (max-width: 767px) {
    .detail-date {
      font-size: clamp(12px, 0.875rem, 14px); } }
  .detail-date span {
    margin-right: 0.25rem; }

.detail-heading {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 1.25rem;
  margin-bottom: 1rem; }

@media (min-width: 1600px) {
  .detail-social {
    position: absolute;
    top: 0.5rem;
    height: 100%;
    right: 100%;
    margin-right: 2.5rem; } }

.detail-social a {
  width: 2rem;
  height: 2rem;
  border-radius: 999999px;
  border: 1px solid #666666;
  color: #666666;
  display: flex;
  align-items: center;
  justify-content: center; }

@media (max-width: 1599px) {
  .detail-social ul {
    display: flex;
    margin-top: 1.25rem; }
    .detail-social ul > * + * {
      margin-left: 0.5rem; } }

@media (min-width: 1600px) {
  .detail-social ul {
    position: sticky;
    top: 10rem; }
    .detail-social ul > * + * {
      margin-top: 0.5rem; } }

.news-detail-page .news-subnav-module {
  display: none; }

.news-latest-list {
  padding: 1.5rem 2rem;
  background-color: #EEF5F7;
  border-radius: 1.25rem; }
  @media (max-width: 767px) {
    .news-latest-list {
      padding: 1.25rem; } }

.news-latest-item {
  display: flex; }
  .news-latest-item:nth-child(n + 2) {
    margin-top: 1.875rem; }
    @media (max-width: 767px) {
      .news-latest-item:nth-child(n + 2) {
        margin-top: 1rem; } }
  .news-latest-item .img {
    max-width: 6.25rem;
    flex: 0 0 6.25rem;
    width: 100%;
    border-radius: 0.5rem;
    overflow: hidden; }
    .news-latest-item .img figure {
      height: 3.75rem;
      overflow: hidden; }
      .news-latest-item .img figure img {
        width: 100%;
        height: 100%;
        object-fit: cover; }
  .news-latest-item .title {
    overflow: hidden;
    display: -webkit-box;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.333333; }
  .news-latest-item .caption {
    padding-left: 1.25rem; }
    .news-latest-item .caption > * + * {
      margin-top: 0.25rem; }

.news-sub-nav {
  padding: 0.25rem 0.75rem;
  background-color: #F5F5F5; }
  @media (max-width: 1023px) {
    .news-sub-nav {
      padding: 0.5rem; } }
  @media (max-width: 1023px) {
    .news-sub-nav ul {
      display: flex;
      overflow-x: auto; } }
  @media (min-width: 1024px) {
    .news-sub-nav ul {
      display: flex;
      flex-wrap: wrap;
      margin-left: -0.5rem;
      margin-bottom: -0.5rem; }
      .news-sub-nav ul > * {
        padding-left: 0.5rem;
        padding-bottom: 0.5rem; } }
  .news-sub-nav ul li a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 2rem;
    border-radius: 999px;
    padding: 0 0.75rem;
    white-space: nowrap;
    color: #666666; }
    .news-sub-nav ul li.active a {
      background-color: #fff;
      color: #211261;
      font-weight: 700; }

#couponRegisterForm {
  width: 72.875rem;
  padding: 0; }
  #couponRegisterForm .popup-form-wrap {
    background: #fff; }
    #couponRegisterForm .popup-form-wrap h2 {
      text-align: center;
      margin-bottom: 1.75rem;
      font-weight: 300;
      color: #211261; }
    #couponRegisterForm .popup-form-wrap .img {
      max-width: 41.50943%;
      flex: 0 0 41.50943%;
      width: 100%; }
      @media (max-width: 767px) {
        #couponRegisterForm .popup-form-wrap .img {
          display: none; } }
      #couponRegisterForm .popup-form-wrap .img figure {
        height: 100%; }
        #couponRegisterForm .popup-form-wrap .img figure img {
          width: 100%;
          height: 100%;
          object-fit: cover; }
    #couponRegisterForm .popup-form-wrap .popup-form {
      flex: 1;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 2.5rem 1.75rem;
      line-height: 1.333333; }
      @media (max-width: 767px) {
        #couponRegisterForm .popup-form-wrap .popup-form {
          padding: 2rem; } }
      #couponRegisterForm .popup-form-wrap .popup-form h2 > * {
        display: block; }
        #couponRegisterForm .popup-form-wrap .popup-form h2 > * + * {
          margin-top: 1rem; }
      #couponRegisterForm .popup-form-wrap .popup-form .row {
        margin-left: -0.875rem;
        margin-bottom: -0.875rem; }
        #couponRegisterForm .popup-form-wrap .popup-form .row > * {
          padding-left: 0.875rem;
          padding-bottom: 0.875rem; }
      #couponRegisterForm .popup-form-wrap .popup-form .frm-btnwrap {
        margin-top: 1.75rem;
        text-align: center; }
        #couponRegisterForm .popup-form-wrap .popup-form .frm-btnwrap .frm-btn {
          width: 11.25rem; }
          #couponRegisterForm .popup-form-wrap .popup-form .frm-btnwrap .frm-btn input {
            text-align: left; }
      #couponRegisterForm .popup-form-wrap .popup-form .form-group input[type="text"], #couponRegisterForm .popup-form-wrap .popup-form .form-group input[type="date"], #couponRegisterForm .popup-form-wrap .popup-form .form-group select, #couponRegisterForm .popup-form-wrap .popup-form .form-group textarea {
        background-color: white;
        border: 0px;
        border: 1px solid #CCCCCC; }
    #couponRegisterForm .popup-form-wrap .popup-footer {
      padding-top: 1.75rem;
      margin-top: 1.75rem;
      border-top: 1px solid rgba(0, 0, 0, 0.2);
      display: flex;
      justify-content: space-between;
      align-items: center; }
      @media (max-width: 575px) {
        #couponRegisterForm .popup-form-wrap .popup-footer {
          flex-direction: column-reverse;
          align-items: flex-start;
          gap: 0.75rem; } }
      #couponRegisterForm .popup-form-wrap .popup-footer figure {
        align-items: center;
        display: flex; }
        #couponRegisterForm .popup-form-wrap .popup-footer figure figcaption {
          display: block;
          flex: auto;
          padding-left: 0.5rem;
          margin-left: 0.5rem;
          border-left: 1px solid #211261;
          font-size: 0.75rem;
          line-height: 1.33333; }
          @media (max-width: 767px) {
            #couponRegisterForm .popup-form-wrap .popup-footer figure figcaption {
              font-size: clamp(12px, 0.75rem, 12px); } }
      #couponRegisterForm .popup-form-wrap .popup-footer a {
        display: flex;
        align-items: center; }
        #couponRegisterForm .popup-form-wrap .popup-footer a em {
          max-width: 2rem;
          flex: 0 0 2rem;
          width: 100%;
          background-color: #211261;
          width: 2rem;
          height: 2rem;
          border-radius: 999999px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center; }
        #couponRegisterForm .popup-form-wrap .popup-footer a span {
          flex: auto;
          padding-left: 0.5rem; }

.service-item {
  background-color: #EEF5F7;
  display: flex;
  flex-direction: column; }
  .service-item .img figure a {
    padding-top: 56.66667%;
    display: block;
    position: relative; }
    .service-item .img figure a img, .service-item .img figure a iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover; }
  .service-item .caption {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.25rem 1.75rem;
    flex: 1 1 0%; }
    .service-item .caption .content {
      margin-bottom: 2.25rem; }
      .service-item .caption .content > * + * {
        margin-top: 1rem; }
    .service-item .caption .brief {
      overflow: hidden;
      display: -webkit-box;
      text-overflow: ellipsis;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical; }
    .service-item .caption .btn {
      margin-top: auto; }

@media (min-width: 1024px) {
  .service-brief-content {
    padding-right: 7.5rem; } }

.service-brief-content > * + * {
  margin-top: 1rem; }

.service-detail-bottom {
  margin-top: 1.5rem; }
  @media (min-width: 1024px) {
    .service-detail-bottom {
      margin-top: 3.75rem; } }

.service-detail-table table {
  width: 100%;
  border: 1px solid #e7e7e7; }
  .service-detail-table table td, .service-detail-table table th {
    padding: 0.75rem 1.75rem;
    text-align: center;
    border-right: 1px solid #e7e7e7;
    font-size: 1rem;
    line-height: 1.5; }
    @media (max-width: 767px) {
      .service-detail-table table td, .service-detail-table table th {
        font-size: clamp(12px, 1rem, 16px); } }
    @media (max-width: 1023px) {
      .service-detail-table table td, .service-detail-table table th {
        padding: 0.75rem 1rem; } }
    .service-detail-table table td:nth-child(2), .service-detail-table table th:nth-child(2) {
      text-align: left;
      width: 75%; }
  .service-detail-table table thead tr th {
    background-color: #211261;
    color: #fff; }
  .service-detail-table table tbody tr:nth-child(even) {
    background-color: #F5F5F5; }

.article-content {
  line-height: 1.33333;
  font-size: 1.125rem;
  font-weight: 300; }
  .article-content > * + * {
    margin-top: 1rem; }
  .article-content ul {
    list-style: disc;
    padding-left: 1.25rem; }
    .article-content ul li::marker {
      color: #211261; }
  .article-content table td, .article-content table th {
    border: 1px solid #dee2e6;
    padding: .75rem;
    vertical-align: top; }
  .article-content h2 {
    font-size: 1.25rem;
    font-weight: 700; }
  .article-content h3 {
    font-size: 1.125rem;
    font-weight: 600; }
  .article-content * + h2 {
    margin-top: 1.75rem; }
  .article-content hr {
    background: none;
    height: auto;
    border-top: 1px solid #F5F5F5; }
  .article-content a {
    color: #55c2e0;
    text-decoration: underline; }

.expand-content {
  overflow: hidden; }
  .expand-content.show {
    max-height: none !important; }

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 3rem;
  min-width: 11.25rem;
  text-transform: uppercase;
  cursor: pointer;
  transition: 0.3s all;
  gap: 1rem; }
  .btn:hover, .btn:focus, .btn:active {
    text-decoration: none;
    outline: none; }
  .btn-pill {
    border-radius: 100px;
    background-clip: padding-box; }
    .btn-pill:before {
      border-radius: 100px;
      background-clip: padding-box; }

.btn-main {
  background-color: #00CE7C;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-main:hover {
      background-color: #009b5d; } }

.btn-border-main {
  border: 1px solid #00CE7C;
  color: #00CE7C;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-main:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #00CE7C;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-main:hover {
      color: #fff; }
      .btn-border-main:hover:before {
        width: 100%; } }

.btn-blue {
  background-color: #211261;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue:hover {
      background-color: #120a36; } }

.btn-border-blue {
  border: 1px solid #211261;
  color: #211261;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-blue:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #211261;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue:hover {
      color: #fff; }
      .btn-border-blue:hover:before {
        width: 100%; } }

.btn-blue-greyish {
  background-color: #7999B6;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue-greyish:hover {
      background-color: #5980a3; } }

.btn-border-blue-greyish {
  border: 1px solid #7999B6;
  color: #7999B6;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-blue-greyish:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #7999B6;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue-greyish:hover {
      color: #333; }
      .btn-border-blue-greyish:hover:before {
        width: 100%; } }

.btn-blue-greyish-light {
  background-color: #EEF5F7;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue-greyish-light:hover {
      background-color: #cbe1e7; } }

.btn-border-blue-greyish-light {
  border: 1px solid #EEF5F7;
  color: #EEF5F7;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-blue-greyish-light:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #EEF5F7;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue-greyish-light:hover {
      color: #333; }
      .btn-border-blue-greyish-light:hover:before {
        width: 100%; } }

.btn-blue-light {
  background-color: #EEF5F6;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-blue-light:hover {
      background-color: #cde1e4; } }

.btn-border-blue-light {
  border: 1px solid #EEF5F6;
  color: #EEF5F6;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-blue-light:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #EEF5F6;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-blue-light:hover {
      color: #333; }
      .btn-border-blue-light:hover:before {
        width: 100%; } }

.btn-green {
  background-color: #74B696;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-green:hover {
      background-color: #55a27d; } }

.btn-border-green {
  border: 1px solid #74B696;
  color: #74B696;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-green:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #74B696;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-green:hover {
      color: #333; }
      .btn-border-green:hover:before {
        width: 100%; } }

.btn-yellow {
  background-color: #E2D39D;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-yellow:hover {
      background-color: #d6c176; } }

.btn-border-yellow {
  border: 1px solid #E2D39D;
  color: #E2D39D;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-yellow:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #E2D39D;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-yellow:hover {
      color: #333; }
      .btn-border-yellow:hover:before {
        width: 100%; } }

.btn-olive {
  background-color: #ACC58C;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-olive:hover {
      background-color: #94b46a; } }

.btn-border-olive {
  border: 1px solid #ACC58C;
  color: #ACC58C;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-olive:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #ACC58C;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-olive:hover {
      color: #333; }
      .btn-border-olive:hover:before {
        width: 100%; } }

.btn-grey {
  background-color: #BFCAC5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey:hover {
      background-color: #a3b3ac; } }

.btn-border-grey {
  border: 1px solid #BFCAC5;
  color: #BFCAC5;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-grey:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #BFCAC5;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey:hover {
      color: #333; }
      .btn-border-grey:hover:before {
        width: 100%; } }

.btn-grey-light {
  background-color: #e7e7e7;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-light:hover {
      background-color: #cecece; } }

.btn-border-grey-light {
  border: 1px solid #e7e7e7;
  color: #e7e7e7;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-grey-light:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #e7e7e7;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-light:hover {
      color: #333; }
      .btn-border-grey-light:hover:before {
        width: 100%; } }

.btn-grey-bright {
  background-color: #F5F5F5;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-bright:hover {
      background-color: gainsboro; } }

.btn-border-grey-bright {
  border: 1px solid #F5F5F5;
  color: #F5F5F5;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-grey-bright:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #F5F5F5;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-bright:hover {
      color: #333; }
      .btn-border-grey-bright:hover:before {
        width: 100%; } }

.btn-grey-666 {
  background-color: #666666;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-666:hover {
      background-color: #4d4d4d; } }

.btn-border-grey-666 {
  border: 1px solid #666666;
  color: #666666;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-grey-666:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #666666;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-666:hover {
      color: #fff; }
      .btn-border-grey-666:hover:before {
        width: 100%; } }

.btn-grey-999 {
  background-color: #999999;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-grey-999:hover {
      background-color: gray; } }

.btn-border-grey-999 {
  border: 1px solid #999999;
  color: #999999;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-grey-999:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #999999;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-grey-999:hover {
      color: #333; }
      .btn-border-grey-999:hover:before {
        width: 100%; } }

.btn-white {
  background-color: #fff;
  color: #333; }
  @media (hover: hover) and (pointer: fine) {
    .btn-white:hover {
      background-color: #e6e6e6; } }

.btn-border-white {
  border: 1px solid #fff;
  color: #fff;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-white:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #fff;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-white:hover {
      color: #333; }
      .btn-border-white:hover:before {
        width: 100%; } }

.btn-body {
  background-color: #333;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-body:hover {
      background-color: #1a1a1a; } }

.btn-border-body {
  border: 1px solid #333;
  color: #333;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-body:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #333;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-body:hover {
      color: #fff; }
      .btn-border-body:hover:before {
        width: 100%; } }

.btn-black {
  background-color: #000;
  color: #fff; }
  @media (hover: hover) and (pointer: fine) {
    .btn-black:hover {
      background-color: black; } }

.btn-border-black {
  border: 1px solid #000;
  color: #000;
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .btn-border-black:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    transition: all 0.3s ease-in-out;
    background-color: #000;
    z-index: -1; }
  @media (hover: hover) and (pointer: fine) {
    .btn-border-black:hover {
      color: #fff; }
      .btn-border-black:hover:before {
        width: 100%; } }

.btn-circle {
  align-items: center;
  justify-content: center;
  text-decoration: none;
  display: inline-flex;
  width: 3.125rem;
  height: 3.125rem;
  border-radius: 999999px;
  font-size: 1.5rem;
  transition: 0.3s all; }
  .btn-circle:hover, .btn-circle:focus, .btn-circle:active {
    text-decoration: none;
    outline: none; }

.swiper-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 999999px;
  color: #211261;
  font-size: 2rem;
  border: 1px solid #211261;
  position: absolute;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  transition: 0.3s all; }
  @media (max-width: 1023px) {
    .swiper-btn {
      display: none; } }
  @media (min-width: 1024px) {
    .swiper-btn {
      margin: 0 1.875rem; }
      .swiper-btn.swiper-prev {
        right: 100%; }
      .swiper-btn.swiper-next {
        left: 100%; } }
  @media (hover: hover) and (pointer: fine) {
    .swiper-btn:hover {
      background-color: #211261;
      color: #fff; } }

.container {
  width: 100%;
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;
  margin-right: auto;
  margin-left: auto; }
  @media (min-width: 576px) {
    .container {
      max-width: 94vw; } }
  @media (min-width: 768px) {
    .container {
      max-width: 92vw; } }
  @media (min-width: 1024px) {
    .container {
      max-width: 1140px; } }
  @media (min-width: 1280px) {
    .container {
      max-width: 1260px; } }
  @media (min-width: 1600px) {
    .container {
      max-width: 1440px; } }

.dropdown {
  position: relative;
  display: inline-block; }

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  right: 0;
  z-index: 1; }
  .dropdown-content > * {
    background-color: #ffffff;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 20px; }

.dropdown:hover .dropdown-content {
  display: block; }

.fz-12 {
  font-size: 0.75rem; }

.fz-14 {
  font-size: 0.875rem; }

.fz-16 {
  font-size: 1rem; }

.fz-18 {
  font-size: 1.125rem; }

.fz-20 {
  font-size: 1.25rem; }

.fz-22 {
  font-size: 1.375rem; }

.fz-24 {
  font-size: 1.5rem; }

.fz-26 {
  font-size: 1.625rem; }

.fz-28 {
  font-size: 1.75rem; }

.fz-30 {
  font-size: 1.875rem; }

.fz-32 {
  font-size: 2rem; }

.fz-34 {
  font-size: 2.125rem; }

.fz-36 {
  font-size: 2.25rem; }

.fz-38 {
  font-size: 2.375rem; }

.fz-40 {
  font-size: 2.5rem; }

@media (min-width: 576px) {
  .fz-sm-12 {
    font-size: 0.75rem; }
  .fz-sm-14 {
    font-size: 0.875rem; }
  .fz-sm-16 {
    font-size: 1rem; }
  .fz-sm-18 {
    font-size: 1.125rem; }
  .fz-sm-20 {
    font-size: 1.25rem; }
  .fz-sm-22 {
    font-size: 1.375rem; }
  .fz-sm-24 {
    font-size: 1.5rem; }
  .fz-sm-26 {
    font-size: 1.625rem; }
  .fz-sm-28 {
    font-size: 1.75rem; }
  .fz-sm-30 {
    font-size: 1.875rem; }
  .fz-sm-32 {
    font-size: 2rem; }
  .fz-sm-34 {
    font-size: 2.125rem; }
  .fz-sm-36 {
    font-size: 2.25rem; }
  .fz-sm-38 {
    font-size: 2.375rem; }
  .fz-sm-40 {
    font-size: 2.5rem; } }

@media (min-width: 768px) {
  .fz-md-12 {
    font-size: 0.75rem; }
  .fz-md-14 {
    font-size: 0.875rem; }
  .fz-md-16 {
    font-size: 1rem; }
  .fz-md-18 {
    font-size: 1.125rem; }
  .fz-md-20 {
    font-size: 1.25rem; }
  .fz-md-22 {
    font-size: 1.375rem; }
  .fz-md-24 {
    font-size: 1.5rem; }
  .fz-md-26 {
    font-size: 1.625rem; }
  .fz-md-28 {
    font-size: 1.75rem; }
  .fz-md-30 {
    font-size: 1.875rem; }
  .fz-md-32 {
    font-size: 2rem; }
  .fz-md-34 {
    font-size: 2.125rem; }
  .fz-md-36 {
    font-size: 2.25rem; }
  .fz-md-38 {
    font-size: 2.375rem; }
  .fz-md-40 {
    font-size: 2.5rem; } }

@media (min-width: 1024px) {
  .fz-lg-12 {
    font-size: 0.75rem; }
  .fz-lg-14 {
    font-size: 0.875rem; }
  .fz-lg-16 {
    font-size: 1rem; }
  .fz-lg-18 {
    font-size: 1.125rem; }
  .fz-lg-20 {
    font-size: 1.25rem; }
  .fz-lg-22 {
    font-size: 1.375rem; }
  .fz-lg-24 {
    font-size: 1.5rem; }
  .fz-lg-26 {
    font-size: 1.625rem; }
  .fz-lg-28 {
    font-size: 1.75rem; }
  .fz-lg-30 {
    font-size: 1.875rem; }
  .fz-lg-32 {
    font-size: 2rem; }
  .fz-lg-34 {
    font-size: 2.125rem; }
  .fz-lg-36 {
    font-size: 2.25rem; }
  .fz-lg-38 {
    font-size: 2.375rem; }
  .fz-lg-40 {
    font-size: 2.5rem; } }

@media (min-width: 1280px) {
  .fz-xl-12 {
    font-size: 0.75rem; }
  .fz-xl-14 {
    font-size: 0.875rem; }
  .fz-xl-16 {
    font-size: 1rem; }
  .fz-xl-18 {
    font-size: 1.125rem; }
  .fz-xl-20 {
    font-size: 1.25rem; }
  .fz-xl-22 {
    font-size: 1.375rem; }
  .fz-xl-24 {
    font-size: 1.5rem; }
  .fz-xl-26 {
    font-size: 1.625rem; }
  .fz-xl-28 {
    font-size: 1.75rem; }
  .fz-xl-30 {
    font-size: 1.875rem; }
  .fz-xl-32 {
    font-size: 2rem; }
  .fz-xl-34 {
    font-size: 2.125rem; }
  .fz-xl-36 {
    font-size: 2.25rem; }
  .fz-xl-38 {
    font-size: 2.375rem; }
  .fz-xl-40 {
    font-size: 2.5rem; } }

@media (min-width: 1600px) {
  .fz-xxl-12 {
    font-size: 0.75rem; }
  .fz-xxl-14 {
    font-size: 0.875rem; }
  .fz-xxl-16 {
    font-size: 1rem; }
  .fz-xxl-18 {
    font-size: 1.125rem; }
  .fz-xxl-20 {
    font-size: 1.25rem; }
  .fz-xxl-22 {
    font-size: 1.375rem; }
  .fz-xxl-24 {
    font-size: 1.5rem; }
  .fz-xxl-26 {
    font-size: 1.625rem; }
  .fz-xxl-28 {
    font-size: 1.75rem; }
  .fz-xxl-30 {
    font-size: 1.875rem; }
  .fz-xxl-32 {
    font-size: 2rem; }
  .fz-xxl-34 {
    font-size: 2.125rem; }
  .fz-xxl-36 {
    font-size: 2.25rem; }
  .fz-xxl-38 {
    font-size: 2.375rem; }
  .fz-xxl-40 {
    font-size: 2.5rem; } }

.form-group input[type="text"], .form-group input[type="date"], .form-group select, .form-group textarea {
  border-radius: 0.3125rem;
  background-clip: padding-box;
  background-color: rgba(255, 255, 255, 0.95);
  border: 0;
  padding: 0.75rem 1.25rem !important;
  width: 100%;
  height: 3rem;
  font-style: normal !important;
  font-size: 1rem !important;
  font-family: 'Lexend', sans-serif !important; }

.form-group select {
  appearance: none;
  background-image: url("../img/sl.png");
  background-repeat: no-repeat;
  background-position: calc(100% - 1.25rem) 50%; }
  .form-group select option {
    color: #333; }

.form-group textarea {
  height: 9.375rem; }

.form-group .RadPicker {
  width: 100% !important;
  position: relative; }
  .form-group .RadPicker .riTextBox {
    background-color: rgba(255, 255, 255, 0.95); }
  .form-group .RadPicker .riEmpty {
    color: #666666; }
  .form-group .RadPicker .rcTable {
    width: 100% !important; }
  .form-group .RadPicker .rcCalPopup {
    position: absolute;
    right: 0.625rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(../img/calendar.png);
    background-position: center !important; }

.form-group.qradio ul {
  margin-bottom: -1.25rem;
  display: flex;
  flex-wrap: wrap; }
  .form-group.qradio ul > * {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc( (100% - (2 - 1) * 1.25rem) / 2);
    margin-bottom: 1.25rem; }
    .form-group.qradio ul > *:not(:last-child) {
      margin-right: 1.25rem; }
    .form-group.qradio ul > *:nth-child(2n) {
      margin-right: 0; }
  .form-group.qradio ul li input {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(100%);
    white-space: nowrap; }
    .form-group.qradio ul li input:checked ~ label {
      color: #211261; }
      .form-group.qradio ul li input:checked ~ label:before {
        content: '\f192'; }
  .form-group.qradio ul li label {
    display: flex; }
    .form-group.qradio ul li label:before {
      content: '\f111';
      font-family: "Font Awesome 6 Pro";
      margin-right: 0.625rem;
      position: relative; }

.frm-btnwrap {
  margin-top: 1.875rem; }

.frm-btn {
  position: relative;
  display: inline-block;
  margin-left: auto;
  min-width: 7.1875rem; }
  .frm-btn:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 1.875rem;
    content: '\f178';
    font-family: "Font Awesome 6 Pro";
    color: #fff;
    pointer-events: none; }
  .frm-btn input[type="submit"] {
    transition: all 0.3s ease-in-out;
    border-radius: 0.3125rem;
    background-clip: padding-box;
    background-color: #211261;
    color: #fff;
    border: 0;
    height: 3.125rem;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    padding: 0 4.375rem 0 1.875rem;
    font-size: 1rem;
    text-transform: uppercase; }

.frm-captcha {
  display: flex;
  flex-wrap: wrap-reverse;
  margin-top: 1.25rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  gap: 1.25rem; }
  .frm-captcha label {
    display: none; }
  .frm-captcha .frm-captcha-input {
    flex: 1; }
  .frm-captcha .RadCaptcha {
    display: flex;
    flex-direction: column-reverse;
    flex: 1;
    max-width: fit-content; }
    .frm-captcha .RadCaptcha img {
      height: 3.125rem !important; }
    .frm-captcha .RadCaptcha > span {
      color: red;
      display: block;
      margin-top: 5px; }
    .frm-captcha .RadCaptcha > div > div {
      display: flex;
      align-items: center; }
    .frm-captcha .RadCaptcha a {
      font-size: 0;
      margin-left: 20px;
      display: none !important; }
      .frm-captcha .RadCaptcha a:before {
        font-family: "Font Awesome 6 Pro";
        content: '\f2ea';
        font-size: 1.6875rem;
        color: #333; }

.form-bg-blue .wrap-form {
  background-color: #211261;
  color: #fff; }
  .form-bg-blue .wrap-form .form-group input[type="text"], .form-bg-blue .wrap-form .form-group input[type="date"], .form-bg-blue .wrap-form .form-group select, .form-bg-blue .wrap-form .form-group textarea {
    background: transparent;
    border: 1px solid #fff;
    color: #fff; }
    .form-bg-blue .wrap-form .form-group input[type="text"]::placeholder, .form-bg-blue .wrap-form .form-group input[type="date"]::placeholder, .form-bg-blue .wrap-form .form-group select::placeholder, .form-bg-blue .wrap-form .form-group textarea::placeholder {
      color: #fff; }
  .form-bg-blue .wrap-form .frm-btnwrap {
    margin-top: 1.25rem; }
    .form-bg-blue .wrap-form .frm-btnwrap input[type="submit"] {
      width: 12.5rem; }

.form-container .wrap-form .qradio .label {
  display: none; }

.form-container .wrap-form .frm-btn {
  height: 3.125rem;
  width: 100%; }
  .form-container .wrap-form .frm-btn input[type="submit"] {
    width: 100%;
    min-width: auto; }

.form-container .wrap-form .frm-btnwrap {
  width: 7.1875rem;
  margin-top: 0 !important; }
  @media (max-width: 575px) {
    .form-container .wrap-form .frm-btnwrap {
      width: 100%; } }

.img-zoom-out img {
  transition: all 0.3s ease-in-out; }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-out:hover img {
    transform: scale(1.05); } }

.img-zoom-in img {
  transition: all 0.3s ease-in-out;
  transform: scale(1.05); }

@media (hover: hover) and (pointer: fine) {
  .img-zoom-in:hover img {
    transform: scale(1); } }

.text-main {
  color: #00CE7C; }

.text-blue {
  color: #211261; }

.text-blue-greyish {
  color: #7999B6; }

.text-blue-greyish-light {
  color: #EEF5F7; }

.text-blue-light {
  color: #EEF5F6; }

.text-green {
  color: #74B696; }

.text-yellow {
  color: #E2D39D; }

.text-olive {
  color: #ACC58C; }

.text-grey {
  color: #BFCAC5; }

.text-grey-light {
  color: #e7e7e7; }

.text-grey-bright {
  color: #F5F5F5; }

.text-grey-666 {
  color: #666666; }

.text-grey-999 {
  color: #999999; }

.text-white {
  color: #fff; }

.text-body {
  color: #333; }

.text-black {
  color: #000; }

.background-main {
  background: #00CE7C; }

.background-blue {
  background: #211261; }

.background-blue-greyish {
  background: #7999B6; }

.background-blue-greyish-light {
  background: #EEF5F7; }

.background-blue-light {
  background: #EEF5F6; }

.background-green {
  background: #74B696; }

.background-yellow {
  background: #E2D39D; }

.background-olive {
  background: #ACC58C; }

.background-grey {
  background: #BFCAC5; }

.background-grey-light {
  background: #e7e7e7; }

.background-grey-bright {
  background: #F5F5F5; }

.background-grey-666 {
  background: #666666; }

.background-grey-999 {
  background: #999999; }

.background-white {
  background: #fff; }

.background-body {
  background: #333; }

.background-black {
  background: #000; }

/* Escape to Parent -------------------------*/
/* Context -------------------------*/
/* Abstract Extend -------------------------*/
/* Forces GPU acceleration of the item, speeding up performance */
/* Cover Background */
/* Flex Center Item */
/* Block Center Item */
/* Reset default style */
/* Text color -------------------------*/
/* Background color -------------------------*/
/* Background color opacity -------------------------*/
/* Background color -------------------------*/
/* Breakpoint -------------------------*/
/* Pseudo -------------------------*/
/* Center Position -------------------------*/
/* Font size, line height rhythm -------------------------*/
/* Child element fill parent size -------------------------*/
/* Content margins -------------------------*/
/* Has Divider -------------------------*/
/* Border Radius -------------------------*/
/* TRBL -------------------------*/
/* Border -------------------------*/
/* Line Clamp -------------------------*/
/* Image Ratio -------------------------*/
/* Image Cover -------------------------*/
/* Box size -------------------------*/
/* Input placeholder -------------------------*/
/* Select background -------------------------*/
/* Circle -------------------------*/
/* Scroll Bar -------------------------*/
/* Hide -------------------------*/
/* Remove -------------------------*/
/* Disable hover on mobile -------------------------*/
/* Calc -------------------------*/
/* Create Flex Row -------------------------*/
/* Override Row -------------------------*/
/* Flex width -------------------------*/
/* Create Flex Gap -------------------------*/
.object-fit-img img {
  font-family: 'object-fit: cover'; }

.modulepager {
  margin-top: 1.875rem; }
  .modulepager .pagination {
    display: flex;
    align-items: center;
    justify-content: center; }
    .modulepager .pagination li {
      list-style: none;
      display: inline-block;
      text-align: center;
      margin-left: 0.5rem; }
      .modulepager .pagination li:first-child {
        margin-left: 0; }
      .modulepager .pagination li a, .modulepager .pagination li span {
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        color: #211261;
        border-radius: 50%;
        background-clip: padding-box;
        border: 1px solid #211261;
        transition: all 0.3s ease-in-out; }
      .modulepager .pagination li.active a, .modulepager .pagination li.active span, .modulepager .pagination li:hover a, .modulepager .pagination li:hover span {
        background-color: #00CE7C;
        color: #211261;
        border-color: #00CE7C; }
      .modulepager .pagination li .FirstPage, .modulepager .pagination li .LastPage, .modulepager .pagination li .BackPage, .modulepager .pagination li .NextPage {
        display: none !important; }

.no-pagination .pages {
  display: none; }

.fadeDown {
  transform: translateY(-50px);
  opacity: 0;
  will-change: transform, opacity; }

.fadeUp {
  transform: translateY(50px);
  opacity: 0;
  will-change: transform, opacity; }

.fadeLeft {
  transform: translateX(50px);
  opacity: 0;
  will-change: transform, opacity; }

.fadeZoomIn {
  transform: scale(1.15);
  opacity: 0;
  will-change: transform, opacity; }

.section-heading {
  padding: 3.75rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.875rem; }
  .section-heading.pb-30 {
    padding-bottom: 1.875rem; }
  @media (max-width: 1023px) {
    .section-heading {
      padding: 1.875rem 0; } }

.section-heading-col {
  display: flex;
  flex-direction: column;
  gap: 1.875rem;
  align-items: flex-start; }
  @media (min-width: 1024px) {
    .section-heading-col {
      padding: 0.625rem 0; } }

.section {
  padding: 3.75rem 0; }
  @media (max-width: 1023px) {
    .section {
      padding: 2rem 0; } }

.mt-0 {
  margin-top: 0rem;
  : 0rem; }

.mt-1 {
  margin-top: 0.25rem;
  : 0.25rem; }

.mt-2 {
  margin-top: 0.5rem;
  : 0.5rem; }

.mt-3 {
  margin-top: 0.75rem;
  : 0.75rem; }

.mt-4 {
  margin-top: 1rem;
  : 1rem; }

.mt-5 {
  margin-top: 1.25rem;
  : 1.25rem; }

.mt-6 {
  margin-top: 1.5rem;
  : 1.5rem; }

.mt-7 {
  margin-top: 1.75rem;
  : 1.75rem; }

.mt-8 {
  margin-top: 2rem;
  : 2rem; }

.mt-9 {
  margin-top: 2.25rem;
  : 2.25rem; }

.mt-10 {
  margin-top: 2.5rem;
  : 2.5rem; }

.mt-11 {
  margin-top: 2.75rem;
  : 2.75rem; }

.mt-12 {
  margin-top: 3rem;
  : 3rem; }

.mt-13 {
  margin-top: 3.25rem;
  : 3.25rem; }

.mt-14 {
  margin-top: 3.5rem;
  : 3.5rem; }

.mt-15 {
  margin-top: 3.75rem;
  : 3.75rem; }

.mt-16 {
  margin-top: 4rem;
  : 4rem; }

.mr-0 {
  margin-right: 0rem;
  : 0rem; }

.mr-1 {
  margin-right: 0.25rem;
  : 0.25rem; }

.mr-2 {
  margin-right: 0.5rem;
  : 0.5rem; }

.mr-3 {
  margin-right: 0.75rem;
  : 0.75rem; }

.mr-4 {
  margin-right: 1rem;
  : 1rem; }

.mr-5 {
  margin-right: 1.25rem;
  : 1.25rem; }

.mr-6 {
  margin-right: 1.5rem;
  : 1.5rem; }

.mr-7 {
  margin-right: 1.75rem;
  : 1.75rem; }

.mr-8 {
  margin-right: 2rem;
  : 2rem; }

.mr-9 {
  margin-right: 2.25rem;
  : 2.25rem; }

.mr-10 {
  margin-right: 2.5rem;
  : 2.5rem; }

.mr-11 {
  margin-right: 2.75rem;
  : 2.75rem; }

.mr-12 {
  margin-right: 3rem;
  : 3rem; }

.mr-13 {
  margin-right: 3.25rem;
  : 3.25rem; }

.mr-14 {
  margin-right: 3.5rem;
  : 3.5rem; }

.mr-15 {
  margin-right: 3.75rem;
  : 3.75rem; }

.mr-16 {
  margin-right: 4rem;
  : 4rem; }

.mb-0 {
  margin-bottom: 0rem;
  : 0rem; }

.mb-1 {
  margin-bottom: 0.25rem;
  : 0.25rem; }

.mb-2 {
  margin-bottom: 0.5rem;
  : 0.5rem; }

.mb-3 {
  margin-bottom: 0.75rem;
  : 0.75rem; }

.mb-4 {
  margin-bottom: 1rem;
  : 1rem; }

.mb-5 {
  margin-bottom: 1.25rem;
  : 1.25rem; }

.mb-6 {
  margin-bottom: 1.5rem;
  : 1.5rem; }

.mb-7 {
  margin-bottom: 1.75rem;
  : 1.75rem; }

.mb-8 {
  margin-bottom: 2rem;
  : 2rem; }

.mb-9 {
  margin-bottom: 2.25rem;
  : 2.25rem; }

.mb-10 {
  margin-bottom: 2.5rem;
  : 2.5rem; }

.mb-11 {
  margin-bottom: 2.75rem;
  : 2.75rem; }

.mb-12 {
  margin-bottom: 3rem;
  : 3rem; }

.mb-13 {
  margin-bottom: 3.25rem;
  : 3.25rem; }

.mb-14 {
  margin-bottom: 3.5rem;
  : 3.5rem; }

.mb-15 {
  margin-bottom: 3.75rem;
  : 3.75rem; }

.mb-16 {
  margin-bottom: 4rem;
  : 4rem; }

.ml-0 {
  margin-left: 0rem;
  : 0rem; }

.ml-1 {
  margin-left: 0.25rem;
  : 0.25rem; }

.ml-2 {
  margin-left: 0.5rem;
  : 0.5rem; }

.ml-3 {
  margin-left: 0.75rem;
  : 0.75rem; }

.ml-4 {
  margin-left: 1rem;
  : 1rem; }

.ml-5 {
  margin-left: 1.25rem;
  : 1.25rem; }

.ml-6 {
  margin-left: 1.5rem;
  : 1.5rem; }

.ml-7 {
  margin-left: 1.75rem;
  : 1.75rem; }

.ml-8 {
  margin-left: 2rem;
  : 2rem; }

.ml-9 {
  margin-left: 2.25rem;
  : 2.25rem; }

.ml-10 {
  margin-left: 2.5rem;
  : 2.5rem; }

.ml-11 {
  margin-left: 2.75rem;
  : 2.75rem; }

.ml-12 {
  margin-left: 3rem;
  : 3rem; }

.ml-13 {
  margin-left: 3.25rem;
  : 3.25rem; }

.ml-14 {
  margin-left: 3.5rem;
  : 3.5rem; }

.ml-15 {
  margin-left: 3.75rem;
  : 3.75rem; }

.ml-16 {
  margin-left: 4rem;
  : 4rem; }

.mx-0 {
  margin-left: 0rem;
  margin-right: 0rem; }

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem; }

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem; }

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem; }

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem; }

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem; }

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem; }

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem; }

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem; }

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem; }

.mx-10 {
  margin-left: 2.5rem;
  margin-right: 2.5rem; }

.mx-11 {
  margin-left: 2.75rem;
  margin-right: 2.75rem; }

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem; }

.mx-13 {
  margin-left: 3.25rem;
  margin-right: 3.25rem; }

.mx-14 {
  margin-left: 3.5rem;
  margin-right: 3.5rem; }

.mx-15 {
  margin-left: 3.75rem;
  margin-right: 3.75rem; }

.mx-16 {
  margin-left: 4rem;
  margin-right: 4rem; }

.my-0 {
  margin-top: 0rem;
  margin-bottom: 0rem; }

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem; }

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem; }

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem; }

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem; }

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem; }

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem; }

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem; }

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem; }

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem; }

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem; }

.my-11 {
  margin-top: 2.75rem;
  margin-bottom: 2.75rem; }

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem; }

.my-13 {
  margin-top: 3.25rem;
  margin-bottom: 3.25rem; }

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem; }

.my-15 {
  margin-top: 3.75rem;
  margin-bottom: 3.75rem; }

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.m-0 {
  margin: 0rem;
  : 0rem; }

.m-1 {
  margin: 0.25rem;
  : 0.25rem; }

.m-2 {
  margin: 0.5rem;
  : 0.5rem; }

.m-3 {
  margin: 0.75rem;
  : 0.75rem; }

.m-4 {
  margin: 1rem;
  : 1rem; }

.m-5 {
  margin: 1.25rem;
  : 1.25rem; }

.m-6 {
  margin: 1.5rem;
  : 1.5rem; }

.m-7 {
  margin: 1.75rem;
  : 1.75rem; }

.m-8 {
  margin: 2rem;
  : 2rem; }

.m-9 {
  margin: 2.25rem;
  : 2.25rem; }

.m-10 {
  margin: 2.5rem;
  : 2.5rem; }

.m-11 {
  margin: 2.75rem;
  : 2.75rem; }

.m-12 {
  margin: 3rem;
  : 3rem; }

.m-13 {
  margin: 3.25rem;
  : 3.25rem; }

.m-14 {
  margin: 3.5rem;
  : 3.5rem; }

.m-15 {
  margin: 3.75rem;
  : 3.75rem; }

.m-16 {
  margin: 4rem;
  : 4rem; }

.pt-0 {
  padding-top: 0rem;
  : 0rem; }

.pt-1 {
  padding-top: 0.25rem;
  : 0.25rem; }

.pt-2 {
  padding-top: 0.5rem;
  : 0.5rem; }

.pt-3 {
  padding-top: 0.75rem;
  : 0.75rem; }

.pt-4 {
  padding-top: 1rem;
  : 1rem; }

.pt-5 {
  padding-top: 1.25rem;
  : 1.25rem; }

.pt-6 {
  padding-top: 1.5rem;
  : 1.5rem; }

.pt-7 {
  padding-top: 1.75rem;
  : 1.75rem; }

.pt-8 {
  padding-top: 2rem;
  : 2rem; }

.pt-9 {
  padding-top: 2.25rem;
  : 2.25rem; }

.pt-10 {
  padding-top: 2.5rem;
  : 2.5rem; }

.pt-11 {
  padding-top: 2.75rem;
  : 2.75rem; }

.pt-12 {
  padding-top: 3rem;
  : 3rem; }

.pt-13 {
  padding-top: 3.25rem;
  : 3.25rem; }

.pt-14 {
  padding-top: 3.5rem;
  : 3.5rem; }

.pt-15 {
  padding-top: 3.75rem;
  : 3.75rem; }

.pt-16 {
  padding-top: 4rem;
  : 4rem; }

.pr-0 {
  padding-right: 0rem;
  : 0rem; }

.pr-1 {
  padding-right: 0.25rem;
  : 0.25rem; }

.pr-2 {
  padding-right: 0.5rem;
  : 0.5rem; }

.pr-3 {
  padding-right: 0.75rem;
  : 0.75rem; }

.pr-4 {
  padding-right: 1rem;
  : 1rem; }

.pr-5 {
  padding-right: 1.25rem;
  : 1.25rem; }

.pr-6 {
  padding-right: 1.5rem;
  : 1.5rem; }

.pr-7 {
  padding-right: 1.75rem;
  : 1.75rem; }

.pr-8 {
  padding-right: 2rem;
  : 2rem; }

.pr-9 {
  padding-right: 2.25rem;
  : 2.25rem; }

.pr-10 {
  padding-right: 2.5rem;
  : 2.5rem; }

.pr-11 {
  padding-right: 2.75rem;
  : 2.75rem; }

.pr-12 {
  padding-right: 3rem;
  : 3rem; }

.pr-13 {
  padding-right: 3.25rem;
  : 3.25rem; }

.pr-14 {
  padding-right: 3.5rem;
  : 3.5rem; }

.pr-15 {
  padding-right: 3.75rem;
  : 3.75rem; }

.pr-16 {
  padding-right: 4rem;
  : 4rem; }

.pb-0 {
  padding-bottom: 0rem;
  : 0rem; }

.pb-1 {
  padding-bottom: 0.25rem;
  : 0.25rem; }

.pb-2 {
  padding-bottom: 0.5rem;
  : 0.5rem; }

.pb-3 {
  padding-bottom: 0.75rem;
  : 0.75rem; }

.pb-4 {
  padding-bottom: 1rem;
  : 1rem; }

.pb-5 {
  padding-bottom: 1.25rem;
  : 1.25rem; }

.pb-6 {
  padding-bottom: 1.5rem;
  : 1.5rem; }

.pb-7 {
  padding-bottom: 1.75rem;
  : 1.75rem; }

.pb-8 {
  padding-bottom: 2rem;
  : 2rem; }

.pb-9 {
  padding-bottom: 2.25rem;
  : 2.25rem; }

.pb-10 {
  padding-bottom: 2.5rem;
  : 2.5rem; }

.pb-11 {
  padding-bottom: 2.75rem;
  : 2.75rem; }

.pb-12 {
  padding-bottom: 3rem;
  : 3rem; }

.pb-13 {
  padding-bottom: 3.25rem;
  : 3.25rem; }

.pb-14 {
  padding-bottom: 3.5rem;
  : 3.5rem; }

.pb-15 {
  padding-bottom: 3.75rem;
  : 3.75rem; }

.pb-16 {
  padding-bottom: 4rem;
  : 4rem; }

.pl-0 {
  padding-left: 0rem;
  : 0rem; }

.pl-1 {
  padding-left: 0.25rem;
  : 0.25rem; }

.pl-2 {
  padding-left: 0.5rem;
  : 0.5rem; }

.pl-3 {
  padding-left: 0.75rem;
  : 0.75rem; }

.pl-4 {
  padding-left: 1rem;
  : 1rem; }

.pl-5 {
  padding-left: 1.25rem;
  : 1.25rem; }

.pl-6 {
  padding-left: 1.5rem;
  : 1.5rem; }

.pl-7 {
  padding-left: 1.75rem;
  : 1.75rem; }

.pl-8 {
  padding-left: 2rem;
  : 2rem; }

.pl-9 {
  padding-left: 2.25rem;
  : 2.25rem; }

.pl-10 {
  padding-left: 2.5rem;
  : 2.5rem; }

.pl-11 {
  padding-left: 2.75rem;
  : 2.75rem; }

.pl-12 {
  padding-left: 3rem;
  : 3rem; }

.pl-13 {
  padding-left: 3.25rem;
  : 3.25rem; }

.pl-14 {
  padding-left: 3.5rem;
  : 3.5rem; }

.pl-15 {
  padding-left: 3.75rem;
  : 3.75rem; }

.pl-16 {
  padding-left: 4rem;
  : 4rem; }

.px-0 {
  padding-left: 0rem;
  padding-right: 0rem; }

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem; }

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem; }

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem; }

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem; }

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem; }

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem; }

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem; }

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem; }

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem; }

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem; }

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem; }

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem; }

.px-13 {
  padding-left: 3.25rem;
  padding-right: 3.25rem; }

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem; }

.px-15 {
  padding-left: 3.75rem;
  padding-right: 3.75rem; }

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem; }

.py-0 {
  padding-top: 0rem;
  padding-bottom: 0rem; }

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem; }

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem; }

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem; }

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem; }

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem; }

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem; }

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem; }

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem; }

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem; }

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem; }

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem; }

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem; }

.py-13 {
  padding-top: 3.25rem;
  padding-bottom: 3.25rem; }

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem; }

.py-15 {
  padding-top: 3.75rem;
  padding-bottom: 3.75rem; }

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.p-0 {
  padding: 0rem;
  : 0rem; }

.p-1 {
  padding: 0.25rem;
  : 0.25rem; }

.p-2 {
  padding: 0.5rem;
  : 0.5rem; }

.p-3 {
  padding: 0.75rem;
  : 0.75rem; }

.p-4 {
  padding: 1rem;
  : 1rem; }

.p-5 {
  padding: 1.25rem;
  : 1.25rem; }

.p-6 {
  padding: 1.5rem;
  : 1.5rem; }

.p-7 {
  padding: 1.75rem;
  : 1.75rem; }

.p-8 {
  padding: 2rem;
  : 2rem; }

.p-9 {
  padding: 2.25rem;
  : 2.25rem; }

.p-10 {
  padding: 2.5rem;
  : 2.5rem; }

.p-11 {
  padding: 2.75rem;
  : 2.75rem; }

.p-12 {
  padding: 3rem;
  : 3rem; }

.p-13 {
  padding: 3.25rem;
  : 3.25rem; }

.p-14 {
  padding: 3.5rem;
  : 3.5rem; }

.p-15 {
  padding: 3.75rem;
  : 3.75rem; }

.p-16 {
  padding: 4rem;
  : 4rem; }

@media (min-width: 576px) {
  .pt-sm-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-sm-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-sm-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-sm-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-sm-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-sm-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-sm-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-sm-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-sm-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-sm-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-sm-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-sm-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-sm-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-sm-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-sm-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-sm-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-sm-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-sm-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-sm-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-sm-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-sm-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-sm-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-sm-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-sm-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-sm-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-sm-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-sm-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-sm-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-sm-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-sm-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-sm-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-sm-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-sm-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-sm-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-sm-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-sm-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-sm-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-sm-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-sm-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-sm-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-sm-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-sm-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-sm-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-sm-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-sm-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-sm-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-sm-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-sm-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-sm-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-sm-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-sm-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-sm-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-sm-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-sm-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-sm-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-sm-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-sm-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-sm-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-sm-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-sm-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-sm-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-sm-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-sm-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-sm-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-sm-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-sm-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-sm-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-sm-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-sm-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-sm-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-sm-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-sm-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-sm-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-sm-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-sm-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-sm-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-sm-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-sm-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-sm-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-sm-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-sm-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-sm-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-sm-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-sm-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-sm-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-sm-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-sm-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-sm-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-sm-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-sm-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-sm-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-sm-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-sm-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-sm-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-sm-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-sm-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-sm-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-sm-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-sm-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-sm-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-sm-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-sm-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-sm-0 {
    padding: 0rem;
    : 0rem; }
  .p-sm-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-sm-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-sm-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-sm-4 {
    padding: 1rem;
    : 1rem; }
  .p-sm-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-sm-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-sm-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-sm-8 {
    padding: 2rem;
    : 2rem; }
  .p-sm-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-sm-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-sm-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-sm-12 {
    padding: 3rem;
    : 3rem; }
  .p-sm-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-sm-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-sm-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-sm-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 576px) {
  .mt-sm-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-sm-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-sm-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-sm-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-sm-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-sm-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-sm-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-sm-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-sm-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-sm-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-sm-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-sm-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-sm-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-sm-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-sm-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-sm-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-sm-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-sm-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-sm-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-sm-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-sm-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-sm-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-sm-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-sm-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-sm-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-sm-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-sm-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-sm-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-sm-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-sm-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-sm-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-sm-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-sm-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-sm-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-sm-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-sm-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-sm-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-sm-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-sm-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-sm-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-sm-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-sm-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-sm-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-sm-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-sm-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-sm-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-sm-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-sm-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-sm-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-sm-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-sm-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-sm-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-sm-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-sm-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-sm-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-sm-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-sm-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-sm-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-sm-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-sm-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-sm-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-sm-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-sm-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-sm-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-sm-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-sm-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-sm-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-sm-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-sm-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-sm-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-sm-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-sm-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-sm-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-sm-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-sm-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-sm-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-sm-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-sm-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-sm-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-sm-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-sm-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-sm-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-sm-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-sm-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-sm-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-sm-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-sm-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-sm-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-sm-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-sm-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-sm-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-sm-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-sm-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-sm-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-sm-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-sm-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-sm-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-sm-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-sm-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-sm-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-sm-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-sm-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-sm-0 {
    margin: 0rem;
    : 0rem; }
  .m-sm-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-sm-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-sm-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-sm-4 {
    margin: 1rem;
    : 1rem; }
  .m-sm-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-sm-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-sm-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-sm-8 {
    margin: 2rem;
    : 2rem; }
  .m-sm-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-sm-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-sm-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-sm-12 {
    margin: 3rem;
    : 3rem; }
  .m-sm-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-sm-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-sm-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-sm-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 768px) {
  .pt-md-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-md-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-md-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-md-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-md-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-md-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-md-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-md-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-md-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-md-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-md-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-md-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-md-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-md-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-md-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-md-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-md-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-md-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-md-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-md-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-md-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-md-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-md-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-md-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-md-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-md-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-md-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-md-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-md-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-md-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-md-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-md-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-md-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-md-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-md-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-md-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-md-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-md-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-md-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-md-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-md-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-md-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-md-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-md-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-md-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-md-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-md-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-md-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-md-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-md-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-md-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-md-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-md-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-md-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-md-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-md-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-md-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-md-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-md-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-md-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-md-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-md-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-md-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-md-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-md-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-md-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-md-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-md-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-md-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-md-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-md-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-md-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-md-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-md-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-md-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-md-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-md-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-md-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-md-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-md-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-md-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-md-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-md-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-md-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-md-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-md-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-md-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-md-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-md-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-md-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-md-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-md-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-md-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-md-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-md-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-md-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-md-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-md-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-md-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-md-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-md-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-md-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-md-0 {
    padding: 0rem;
    : 0rem; }
  .p-md-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-md-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-md-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-md-4 {
    padding: 1rem;
    : 1rem; }
  .p-md-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-md-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-md-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-md-8 {
    padding: 2rem;
    : 2rem; }
  .p-md-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-md-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-md-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-md-12 {
    padding: 3rem;
    : 3rem; }
  .p-md-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-md-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-md-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-md-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-md-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-md-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-md-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-md-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-md-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-md-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-md-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-md-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-md-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-md-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-md-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-md-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-md-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-md-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-md-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-md-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-md-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-md-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-md-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-md-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-md-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-md-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-md-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-md-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-md-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-md-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-md-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-md-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-md-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-md-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-md-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-md-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-md-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-md-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-md-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-md-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-md-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-md-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-md-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-md-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-md-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-md-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-md-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-md-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-md-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-md-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-md-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-md-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-md-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-md-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-md-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-md-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-md-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-md-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-md-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-md-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-md-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-md-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-md-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-md-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-md-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-md-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-md-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-md-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-md-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-md-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-md-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-md-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-md-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-md-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-md-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-md-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-md-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-md-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-md-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-md-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-md-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-md-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-md-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-md-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-md-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-md-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-md-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-md-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-md-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-md-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-md-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-md-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-md-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-md-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-md-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-md-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-md-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-md-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-md-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-md-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-md-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-md-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-md-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-md-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-md-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-md-0 {
    margin: 0rem;
    : 0rem; }
  .m-md-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-md-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-md-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-md-4 {
    margin: 1rem;
    : 1rem; }
  .m-md-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-md-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-md-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-md-8 {
    margin: 2rem;
    : 2rem; }
  .m-md-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-md-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-md-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-md-12 {
    margin: 3rem;
    : 3rem; }
  .m-md-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-md-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-md-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-md-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1024px) {
  .pt-lg-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-lg-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-lg-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-lg-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-lg-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-lg-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-lg-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-lg-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-lg-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-lg-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-lg-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-lg-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-lg-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-lg-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-lg-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-lg-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-lg-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-lg-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-lg-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-lg-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-lg-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-lg-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-lg-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-lg-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-lg-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-lg-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-lg-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-lg-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-lg-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-lg-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-lg-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-lg-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-lg-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-lg-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-lg-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-lg-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-lg-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-lg-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-lg-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-lg-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-lg-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-lg-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-lg-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-lg-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-lg-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-lg-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-lg-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-lg-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-lg-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-lg-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-lg-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-lg-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-lg-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-lg-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-lg-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-lg-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-lg-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-lg-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-lg-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-lg-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-lg-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-lg-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-lg-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-lg-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-lg-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-lg-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-lg-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-lg-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-lg-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-lg-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-lg-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-lg-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-lg-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-lg-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-lg-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-lg-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-lg-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-lg-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-lg-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-lg-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-lg-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-lg-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-lg-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-lg-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-lg-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-lg-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-lg-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-lg-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-lg-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-lg-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-lg-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-lg-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-lg-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-lg-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-lg-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-lg-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-lg-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-lg-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-lg-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-lg-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-lg-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-lg-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-lg-0 {
    padding: 0rem;
    : 0rem; }
  .p-lg-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-lg-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-lg-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-lg-4 {
    padding: 1rem;
    : 1rem; }
  .p-lg-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-lg-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-lg-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-lg-8 {
    padding: 2rem;
    : 2rem; }
  .p-lg-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-lg-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-lg-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-lg-12 {
    padding: 3rem;
    : 3rem; }
  .p-lg-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-lg-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-lg-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-lg-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1024px) {
  .mt-lg-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-lg-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-lg-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-lg-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-lg-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-lg-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-lg-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-lg-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-lg-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-lg-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-lg-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-lg-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-lg-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-lg-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-lg-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-lg-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-lg-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-lg-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-lg-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-lg-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-lg-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-lg-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-lg-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-lg-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-lg-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-lg-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-lg-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-lg-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-lg-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-lg-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-lg-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-lg-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-lg-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-lg-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-lg-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-lg-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-lg-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-lg-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-lg-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-lg-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-lg-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-lg-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-lg-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-lg-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-lg-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-lg-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-lg-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-lg-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-lg-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-lg-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-lg-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-lg-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-lg-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-lg-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-lg-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-lg-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-lg-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-lg-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-lg-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-lg-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-lg-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-lg-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-lg-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-lg-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-lg-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-lg-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-lg-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-lg-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-lg-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-lg-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-lg-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-lg-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-lg-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-lg-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-lg-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-lg-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-lg-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-lg-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-lg-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-lg-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-lg-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-lg-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-lg-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-lg-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-lg-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-lg-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-lg-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-lg-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-lg-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-lg-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-lg-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-lg-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-lg-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-lg-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-lg-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-lg-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-lg-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-lg-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-lg-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-lg-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-lg-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-lg-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-lg-0 {
    margin: 0rem;
    : 0rem; }
  .m-lg-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-lg-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-lg-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-lg-4 {
    margin: 1rem;
    : 1rem; }
  .m-lg-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-lg-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-lg-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-lg-8 {
    margin: 2rem;
    : 2rem; }
  .m-lg-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-lg-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-lg-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-lg-12 {
    margin: 3rem;
    : 3rem; }
  .m-lg-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-lg-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-lg-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-lg-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1280px) {
  .pt-xl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1280px) {
  .mt-xl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xl-16 {
    margin: 4rem;
    : 4rem; } }

@media (min-width: 1600px) {
  .pt-xxl-0 {
    padding-top: 0rem;
    : 0rem; }
  .pt-xxl-1 {
    padding-top: 0.25rem;
    : 0.25rem; }
  .pt-xxl-2 {
    padding-top: 0.5rem;
    : 0.5rem; }
  .pt-xxl-3 {
    padding-top: 0.75rem;
    : 0.75rem; }
  .pt-xxl-4 {
    padding-top: 1rem;
    : 1rem; }
  .pt-xxl-5 {
    padding-top: 1.25rem;
    : 1.25rem; }
  .pt-xxl-6 {
    padding-top: 1.5rem;
    : 1.5rem; }
  .pt-xxl-7 {
    padding-top: 1.75rem;
    : 1.75rem; }
  .pt-xxl-8 {
    padding-top: 2rem;
    : 2rem; }
  .pt-xxl-9 {
    padding-top: 2.25rem;
    : 2.25rem; }
  .pt-xxl-10 {
    padding-top: 2.5rem;
    : 2.5rem; }
  .pt-xxl-11 {
    padding-top: 2.75rem;
    : 2.75rem; }
  .pt-xxl-12 {
    padding-top: 3rem;
    : 3rem; }
  .pt-xxl-13 {
    padding-top: 3.25rem;
    : 3.25rem; }
  .pt-xxl-14 {
    padding-top: 3.5rem;
    : 3.5rem; }
  .pt-xxl-15 {
    padding-top: 3.75rem;
    : 3.75rem; }
  .pt-xxl-16 {
    padding-top: 4rem;
    : 4rem; }
  .pr-xxl-0 {
    padding-right: 0rem;
    : 0rem; }
  .pr-xxl-1 {
    padding-right: 0.25rem;
    : 0.25rem; }
  .pr-xxl-2 {
    padding-right: 0.5rem;
    : 0.5rem; }
  .pr-xxl-3 {
    padding-right: 0.75rem;
    : 0.75rem; }
  .pr-xxl-4 {
    padding-right: 1rem;
    : 1rem; }
  .pr-xxl-5 {
    padding-right: 1.25rem;
    : 1.25rem; }
  .pr-xxl-6 {
    padding-right: 1.5rem;
    : 1.5rem; }
  .pr-xxl-7 {
    padding-right: 1.75rem;
    : 1.75rem; }
  .pr-xxl-8 {
    padding-right: 2rem;
    : 2rem; }
  .pr-xxl-9 {
    padding-right: 2.25rem;
    : 2.25rem; }
  .pr-xxl-10 {
    padding-right: 2.5rem;
    : 2.5rem; }
  .pr-xxl-11 {
    padding-right: 2.75rem;
    : 2.75rem; }
  .pr-xxl-12 {
    padding-right: 3rem;
    : 3rem; }
  .pr-xxl-13 {
    padding-right: 3.25rem;
    : 3.25rem; }
  .pr-xxl-14 {
    padding-right: 3.5rem;
    : 3.5rem; }
  .pr-xxl-15 {
    padding-right: 3.75rem;
    : 3.75rem; }
  .pr-xxl-16 {
    padding-right: 4rem;
    : 4rem; }
  .pb-xxl-0 {
    padding-bottom: 0rem;
    : 0rem; }
  .pb-xxl-1 {
    padding-bottom: 0.25rem;
    : 0.25rem; }
  .pb-xxl-2 {
    padding-bottom: 0.5rem;
    : 0.5rem; }
  .pb-xxl-3 {
    padding-bottom: 0.75rem;
    : 0.75rem; }
  .pb-xxl-4 {
    padding-bottom: 1rem;
    : 1rem; }
  .pb-xxl-5 {
    padding-bottom: 1.25rem;
    : 1.25rem; }
  .pb-xxl-6 {
    padding-bottom: 1.5rem;
    : 1.5rem; }
  .pb-xxl-7 {
    padding-bottom: 1.75rem;
    : 1.75rem; }
  .pb-xxl-8 {
    padding-bottom: 2rem;
    : 2rem; }
  .pb-xxl-9 {
    padding-bottom: 2.25rem;
    : 2.25rem; }
  .pb-xxl-10 {
    padding-bottom: 2.5rem;
    : 2.5rem; }
  .pb-xxl-11 {
    padding-bottom: 2.75rem;
    : 2.75rem; }
  .pb-xxl-12 {
    padding-bottom: 3rem;
    : 3rem; }
  .pb-xxl-13 {
    padding-bottom: 3.25rem;
    : 3.25rem; }
  .pb-xxl-14 {
    padding-bottom: 3.5rem;
    : 3.5rem; }
  .pb-xxl-15 {
    padding-bottom: 3.75rem;
    : 3.75rem; }
  .pb-xxl-16 {
    padding-bottom: 4rem;
    : 4rem; }
  .pl-xxl-0 {
    padding-left: 0rem;
    : 0rem; }
  .pl-xxl-1 {
    padding-left: 0.25rem;
    : 0.25rem; }
  .pl-xxl-2 {
    padding-left: 0.5rem;
    : 0.5rem; }
  .pl-xxl-3 {
    padding-left: 0.75rem;
    : 0.75rem; }
  .pl-xxl-4 {
    padding-left: 1rem;
    : 1rem; }
  .pl-xxl-5 {
    padding-left: 1.25rem;
    : 1.25rem; }
  .pl-xxl-6 {
    padding-left: 1.5rem;
    : 1.5rem; }
  .pl-xxl-7 {
    padding-left: 1.75rem;
    : 1.75rem; }
  .pl-xxl-8 {
    padding-left: 2rem;
    : 2rem; }
  .pl-xxl-9 {
    padding-left: 2.25rem;
    : 2.25rem; }
  .pl-xxl-10 {
    padding-left: 2.5rem;
    : 2.5rem; }
  .pl-xxl-11 {
    padding-left: 2.75rem;
    : 2.75rem; }
  .pl-xxl-12 {
    padding-left: 3rem;
    : 3rem; }
  .pl-xxl-13 {
    padding-left: 3.25rem;
    : 3.25rem; }
  .pl-xxl-14 {
    padding-left: 3.5rem;
    : 3.5rem; }
  .pl-xxl-15 {
    padding-left: 3.75rem;
    : 3.75rem; }
  .pl-xxl-16 {
    padding-left: 4rem;
    : 4rem; }
  .px-xxl-0 {
    padding-left: 0rem;
    padding-right: 0rem; }
  .px-xxl-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }
  .px-xxl-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }
  .px-xxl-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem; }
  .px-xxl-4 {
    padding-left: 1rem;
    padding-right: 1rem; }
  .px-xxl-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }
  .px-xxl-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem; }
  .px-xxl-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem; }
  .px-xxl-8 {
    padding-left: 2rem;
    padding-right: 2rem; }
  .px-xxl-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem; }
  .px-xxl-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }
  .px-xxl-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem; }
  .px-xxl-12 {
    padding-left: 3rem;
    padding-right: 3rem; }
  .px-xxl-13 {
    padding-left: 3.25rem;
    padding-right: 3.25rem; }
  .px-xxl-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem; }
  .px-xxl-15 {
    padding-left: 3.75rem;
    padding-right: 3.75rem; }
  .px-xxl-16 {
    padding-left: 4rem;
    padding-right: 4rem; }
  .py-xxl-0 {
    padding-top: 0rem;
    padding-bottom: 0rem; }
  .py-xxl-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }
  .py-xxl-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }
  .py-xxl-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem; }
  .py-xxl-4 {
    padding-top: 1rem;
    padding-bottom: 1rem; }
  .py-xxl-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }
  .py-xxl-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
  .py-xxl-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem; }
  .py-xxl-8 {
    padding-top: 2rem;
    padding-bottom: 2rem; }
  .py-xxl-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem; }
  .py-xxl-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }
  .py-xxl-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem; }
  .py-xxl-12 {
    padding-top: 3rem;
    padding-bottom: 3rem; }
  .py-xxl-13 {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem; }
  .py-xxl-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem; }
  .py-xxl-15 {
    padding-top: 3.75rem;
    padding-bottom: 3.75rem; }
  .py-xxl-16 {
    padding-top: 4rem;
    padding-bottom: 4rem; }
  .p-xxl-0 {
    padding: 0rem;
    : 0rem; }
  .p-xxl-1 {
    padding: 0.25rem;
    : 0.25rem; }
  .p-xxl-2 {
    padding: 0.5rem;
    : 0.5rem; }
  .p-xxl-3 {
    padding: 0.75rem;
    : 0.75rem; }
  .p-xxl-4 {
    padding: 1rem;
    : 1rem; }
  .p-xxl-5 {
    padding: 1.25rem;
    : 1.25rem; }
  .p-xxl-6 {
    padding: 1.5rem;
    : 1.5rem; }
  .p-xxl-7 {
    padding: 1.75rem;
    : 1.75rem; }
  .p-xxl-8 {
    padding: 2rem;
    : 2rem; }
  .p-xxl-9 {
    padding: 2.25rem;
    : 2.25rem; }
  .p-xxl-10 {
    padding: 2.5rem;
    : 2.5rem; }
  .p-xxl-11 {
    padding: 2.75rem;
    : 2.75rem; }
  .p-xxl-12 {
    padding: 3rem;
    : 3rem; }
  .p-xxl-13 {
    padding: 3.25rem;
    : 3.25rem; }
  .p-xxl-14 {
    padding: 3.5rem;
    : 3.5rem; }
  .p-xxl-15 {
    padding: 3.75rem;
    : 3.75rem; }
  .p-xxl-16 {
    padding: 4rem;
    : 4rem; } }

@media (min-width: 1600px) {
  .mt-xxl-0 {
    margin-top: 0rem;
    : 0rem; }
  .mt-xxl-1 {
    margin-top: 0.25rem;
    : 0.25rem; }
  .mt-xxl-2 {
    margin-top: 0.5rem;
    : 0.5rem; }
  .mt-xxl-3 {
    margin-top: 0.75rem;
    : 0.75rem; }
  .mt-xxl-4 {
    margin-top: 1rem;
    : 1rem; }
  .mt-xxl-5 {
    margin-top: 1.25rem;
    : 1.25rem; }
  .mt-xxl-6 {
    margin-top: 1.5rem;
    : 1.5rem; }
  .mt-xxl-7 {
    margin-top: 1.75rem;
    : 1.75rem; }
  .mt-xxl-8 {
    margin-top: 2rem;
    : 2rem; }
  .mt-xxl-9 {
    margin-top: 2.25rem;
    : 2.25rem; }
  .mt-xxl-10 {
    margin-top: 2.5rem;
    : 2.5rem; }
  .mt-xxl-11 {
    margin-top: 2.75rem;
    : 2.75rem; }
  .mt-xxl-12 {
    margin-top: 3rem;
    : 3rem; }
  .mt-xxl-13 {
    margin-top: 3.25rem;
    : 3.25rem; }
  .mt-xxl-14 {
    margin-top: 3.5rem;
    : 3.5rem; }
  .mt-xxl-15 {
    margin-top: 3.75rem;
    : 3.75rem; }
  .mt-xxl-16 {
    margin-top: 4rem;
    : 4rem; }
  .mr-xxl-0 {
    margin-right: 0rem;
    : 0rem; }
  .mr-xxl-1 {
    margin-right: 0.25rem;
    : 0.25rem; }
  .mr-xxl-2 {
    margin-right: 0.5rem;
    : 0.5rem; }
  .mr-xxl-3 {
    margin-right: 0.75rem;
    : 0.75rem; }
  .mr-xxl-4 {
    margin-right: 1rem;
    : 1rem; }
  .mr-xxl-5 {
    margin-right: 1.25rem;
    : 1.25rem; }
  .mr-xxl-6 {
    margin-right: 1.5rem;
    : 1.5rem; }
  .mr-xxl-7 {
    margin-right: 1.75rem;
    : 1.75rem; }
  .mr-xxl-8 {
    margin-right: 2rem;
    : 2rem; }
  .mr-xxl-9 {
    margin-right: 2.25rem;
    : 2.25rem; }
  .mr-xxl-10 {
    margin-right: 2.5rem;
    : 2.5rem; }
  .mr-xxl-11 {
    margin-right: 2.75rem;
    : 2.75rem; }
  .mr-xxl-12 {
    margin-right: 3rem;
    : 3rem; }
  .mr-xxl-13 {
    margin-right: 3.25rem;
    : 3.25rem; }
  .mr-xxl-14 {
    margin-right: 3.5rem;
    : 3.5rem; }
  .mr-xxl-15 {
    margin-right: 3.75rem;
    : 3.75rem; }
  .mr-xxl-16 {
    margin-right: 4rem;
    : 4rem; }
  .mb-xxl-0 {
    margin-bottom: 0rem;
    : 0rem; }
  .mb-xxl-1 {
    margin-bottom: 0.25rem;
    : 0.25rem; }
  .mb-xxl-2 {
    margin-bottom: 0.5rem;
    : 0.5rem; }
  .mb-xxl-3 {
    margin-bottom: 0.75rem;
    : 0.75rem; }
  .mb-xxl-4 {
    margin-bottom: 1rem;
    : 1rem; }
  .mb-xxl-5 {
    margin-bottom: 1.25rem;
    : 1.25rem; }
  .mb-xxl-6 {
    margin-bottom: 1.5rem;
    : 1.5rem; }
  .mb-xxl-7 {
    margin-bottom: 1.75rem;
    : 1.75rem; }
  .mb-xxl-8 {
    margin-bottom: 2rem;
    : 2rem; }
  .mb-xxl-9 {
    margin-bottom: 2.25rem;
    : 2.25rem; }
  .mb-xxl-10 {
    margin-bottom: 2.5rem;
    : 2.5rem; }
  .mb-xxl-11 {
    margin-bottom: 2.75rem;
    : 2.75rem; }
  .mb-xxl-12 {
    margin-bottom: 3rem;
    : 3rem; }
  .mb-xxl-13 {
    margin-bottom: 3.25rem;
    : 3.25rem; }
  .mb-xxl-14 {
    margin-bottom: 3.5rem;
    : 3.5rem; }
  .mb-xxl-15 {
    margin-bottom: 3.75rem;
    : 3.75rem; }
  .mb-xxl-16 {
    margin-bottom: 4rem;
    : 4rem; }
  .ml-xxl-0 {
    margin-left: 0rem;
    : 0rem; }
  .ml-xxl-1 {
    margin-left: 0.25rem;
    : 0.25rem; }
  .ml-xxl-2 {
    margin-left: 0.5rem;
    : 0.5rem; }
  .ml-xxl-3 {
    margin-left: 0.75rem;
    : 0.75rem; }
  .ml-xxl-4 {
    margin-left: 1rem;
    : 1rem; }
  .ml-xxl-5 {
    margin-left: 1.25rem;
    : 1.25rem; }
  .ml-xxl-6 {
    margin-left: 1.5rem;
    : 1.5rem; }
  .ml-xxl-7 {
    margin-left: 1.75rem;
    : 1.75rem; }
  .ml-xxl-8 {
    margin-left: 2rem;
    : 2rem; }
  .ml-xxl-9 {
    margin-left: 2.25rem;
    : 2.25rem; }
  .ml-xxl-10 {
    margin-left: 2.5rem;
    : 2.5rem; }
  .ml-xxl-11 {
    margin-left: 2.75rem;
    : 2.75rem; }
  .ml-xxl-12 {
    margin-left: 3rem;
    : 3rem; }
  .ml-xxl-13 {
    margin-left: 3.25rem;
    : 3.25rem; }
  .ml-xxl-14 {
    margin-left: 3.5rem;
    : 3.5rem; }
  .ml-xxl-15 {
    margin-left: 3.75rem;
    : 3.75rem; }
  .ml-xxl-16 {
    margin-left: 4rem;
    : 4rem; }
  .mx-xxl-0 {
    margin-left: 0rem;
    margin-right: 0rem; }
  .mx-xxl-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem; }
  .mx-xxl-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem; }
  .mx-xxl-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem; }
  .mx-xxl-4 {
    margin-left: 1rem;
    margin-right: 1rem; }
  .mx-xxl-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .mx-xxl-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem; }
  .mx-xxl-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem; }
  .mx-xxl-8 {
    margin-left: 2rem;
    margin-right: 2rem; }
  .mx-xxl-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem; }
  .mx-xxl-10 {
    margin-left: 2.5rem;
    margin-right: 2.5rem; }
  .mx-xxl-11 {
    margin-left: 2.75rem;
    margin-right: 2.75rem; }
  .mx-xxl-12 {
    margin-left: 3rem;
    margin-right: 3rem; }
  .mx-xxl-13 {
    margin-left: 3.25rem;
    margin-right: 3.25rem; }
  .mx-xxl-14 {
    margin-left: 3.5rem;
    margin-right: 3.5rem; }
  .mx-xxl-15 {
    margin-left: 3.75rem;
    margin-right: 3.75rem; }
  .mx-xxl-16 {
    margin-left: 4rem;
    margin-right: 4rem; }
  .my-xxl-0 {
    margin-top: 0rem;
    margin-bottom: 0rem; }
  .my-xxl-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem; }
  .my-xxl-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem; }
  .my-xxl-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem; }
  .my-xxl-4 {
    margin-top: 1rem;
    margin-bottom: 1rem; }
  .my-xxl-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  .my-xxl-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem; }
  .my-xxl-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem; }
  .my-xxl-8 {
    margin-top: 2rem;
    margin-bottom: 2rem; }
  .my-xxl-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem; }
  .my-xxl-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem; }
  .my-xxl-11 {
    margin-top: 2.75rem;
    margin-bottom: 2.75rem; }
  .my-xxl-12 {
    margin-top: 3rem;
    margin-bottom: 3rem; }
  .my-xxl-13 {
    margin-top: 3.25rem;
    margin-bottom: 3.25rem; }
  .my-xxl-14 {
    margin-top: 3.5rem;
    margin-bottom: 3.5rem; }
  .my-xxl-15 {
    margin-top: 3.75rem;
    margin-bottom: 3.75rem; }
  .my-xxl-16 {
    margin-top: 4rem;
    margin-bottom: 4rem; }
  .m-xxl-0 {
    margin: 0rem;
    : 0rem; }
  .m-xxl-1 {
    margin: 0.25rem;
    : 0.25rem; }
  .m-xxl-2 {
    margin: 0.5rem;
    : 0.5rem; }
  .m-xxl-3 {
    margin: 0.75rem;
    : 0.75rem; }
  .m-xxl-4 {
    margin: 1rem;
    : 1rem; }
  .m-xxl-5 {
    margin: 1.25rem;
    : 1.25rem; }
  .m-xxl-6 {
    margin: 1.5rem;
    : 1.5rem; }
  .m-xxl-7 {
    margin: 1.75rem;
    : 1.75rem; }
  .m-xxl-8 {
    margin: 2rem;
    : 2rem; }
  .m-xxl-9 {
    margin: 2.25rem;
    : 2.25rem; }
  .m-xxl-10 {
    margin: 2.5rem;
    : 2.5rem; }
  .m-xxl-11 {
    margin: 2.75rem;
    : 2.75rem; }
  .m-xxl-12 {
    margin: 3rem;
    : 3rem; }
  .m-xxl-13 {
    margin: 3.25rem;
    : 3.25rem; }
  .m-xxl-14 {
    margin: 3.5rem;
    : 3.5rem; }
  .m-xxl-15 {
    margin: 3.75rem;
    : 3.75rem; }
  .m-xxl-16 {
    margin: 4rem;
    : 4rem; } }

.pb-100 {
  padding-bottom: 6.25rem; }
  @media (max-width: 1023px) {
    .pb-100 {
      padding-bottom: 2.5rem; } }

.pb-60 {
  padding-bottom: 3.75rem; }
  @media (max-width: 1023px) {
    .pb-60 {
      padding-bottom: 2.5rem; } }

.heading-1 {
  font-size: 3rem;
  line-height: 1.25;
  letter-spacing: -0.04em; }
  @media (max-width: 767px) {
    .heading-1 {
      font-size: clamp(12px, 3rem, 48px); } }

.heading-4, #couponRegisterForm .popup-form-wrap h2 {
  font-size: 2rem;
  line-height: 1.25; }
  @media (max-width: 767px) {
    .heading-4, #couponRegisterForm .popup-form-wrap h2 {
      font-size: clamp(12px, 2rem, 32px); } }

.heading-5 {
  font-size: 1.5rem;
  line-height: 1.33333; }
  @media (max-width: 767px) {
    .heading-5 {
      font-size: clamp(12px, 1.5rem, 24px); } }

.text-body-1, .footer-item h3, .footer-item strong {
  font-size: 1.25rem;
  line-height: 1.2; }
  @media (max-width: 767px) {
    .text-body-1, .footer-item h3, .footer-item strong {
      font-size: clamp(12px, 1.25rem, 20px); } }

.text-body-2 {
  font-size: 1.125rem;
  line-height: 1.33333; }
  @media (max-width: 767px) {
    .text-body-2 {
      font-size: clamp(12px, 1.125rem, 18px); } }

.text-body-3, .section-heading .section-desc {
  font-size: 1rem;
  line-height: 1.25; }
  @media (max-width: 767px) {
    .text-body-3, .section-heading .section-desc {
      font-size: clamp(12px, 1rem, 16px); } }

.text-body-4, .fixed-stuff ul li.has-tooltip .tooltip, .footer-item, .about-7 ul.tabs-nav li a, .home-news-tabs .tab-nav li a, .news-sub-nav ul li a, #couponRegisterForm .popup-form-wrap .popup-footer a span {
  font-size: 0.875rem;
  line-height: 1.42857; }
  @media (max-width: 767px) {
    .text-body-4, .fixed-stuff ul li.has-tooltip .tooltip, .footer-item, .about-7 ul.tabs-nav li a, .home-news-tabs .tab-nav li a, .news-sub-nav ul li a, #couponRegisterForm .popup-form-wrap .popup-footer a span {
      font-size: clamp(12px, 0.875rem, 14px); } }

.text-body-5, .footer-bottom {
  font-size: 0.75rem;
  line-height: 1.33333; }
  @media (max-width: 767px) {
    .text-body-5, .footer-bottom {
      font-size: clamp(12px, 0.75rem, 12px); } }

.flex {
  display: flex; }

.inline-flex {
  display: inline-flex; }

.flex-wrap {
  flex-wrap: wrap; }

.flex-column {
  flex-direction: column; }

.flex-column-reverse {
  flex-direction: column-reverse; }

.flex-row {
  flex-direction: row; }

.flex-row-reverse {
  flex-direction: row-reverse; }

.flex-center {
  justify-content: center;
  align-items: center; }

.justify-start {
  justify-content: flex-start; }

.justify-end {
  justify-content: flex-end; }

.justify-center {
  justify-content: center; }

.justify-between {
  justify-content: space-between; }

.items-center {
  align-items: center; }

.items-start {
  align-items: flex-start; }

.items-end {
  align-items: flex-end; }

.items-baseline {
  align-items: baseline; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.inline-flex {
  display: inline-flex; }

.inline {
  display: inline; }

.hidden, .d-none, [hidden] {
  display: none; }

@media screen and (max-width: 1023px) {
  .hidden-mobile {
    display: none; } }

@media screen and (min-width: 1024px) {
  .hidden-desktop {
    display: none; } }

.text-transform-none {
  text-transform: none; }

.text-uppercase {
  text-transform: uppercase; }

.text-capitalize {
  text-transform: capitalize; }

.font-light {
  font-weight: 300; }

.font-normal {
  font-weight: 400; }

.font-medium {
  font-weight: 500; }

.font-semibold {
  font-weight: 600; }

.font-bold, .text-bold {
  font-weight: 700; }

.font-italic {
  font-style: italic; }

.text-center {
  text-align: center; }

.text-left {
  text-align: left; }

.text-right {
  text-align: right; }

.text-justify {
  text-align: justify; }

.position-relative {
  position: relative; }

.position-absolute {
  position: absolute; }

.position-fixed {
  position: fixed; }

.position-sticky {
  position: sticky; }

.top-0 {
  top: 0; }

.bottom-0 {
  bottom: 0; }

.left-0 {
  left: 0; }

.right-0 {
  right: 0; }

.rounded {
  border-radius: 50%; }

.w-100 {
  width: 100%; }

.h-100 {
  height: 100%; }

.leading-tight {
  line-height: 1.25; }

.leading-snug {
  line-height: 1.375; }

.leading-normal {
  line-height: 1.5; }

.leading-relaxed {
  line-height: 1.625; }

.leading-loose {
  line-height: 2; }

.overflow-hidden {
  overflow: hidden; }

.background-cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
