
#couponRegisterForm
	width: r(1166px)
	padding: 0
	.popup-form-wrap
		background: #fff
		h2
			@extend .heading-4
			text-align: center
			margin-bottom: r(28px)
			font-weight: 300
			+text(blue)
		.img
			+flex-width((484 / 1166) * 100%)
			+remove(md,max)
			figure
				height: 100%
				img
					width: 100%
					height: 100%
					object-fit: cover
		.popup-form
			flex: 1
			position: relative
			z-index: 1
			display: flex
			flex-direction: column
			justify-content: center
			padding: r(40px) r(28px)
			line-height: 1.333333
			+mq-max(md)
				padding: r(32px)
			h2
				> *
					display: block
					& + *
						margin-top: r(16px)
			.row
				+gap-override(14px)
			.frm-btnwrap
				margin-top: r(28px)
				text-align: center
				.frm-btn
					width: r(180px)
					input
						text-align: left
			.form-group
				+all-input
					background-color: white
					border: 0px
					border: 1px solid #CCCCCC
		.popup-footer
			padding-top: r(28px)
			margin-top: r(28px)
			border-top: 1px solid rgba(0, 0, 0, 0.2)
			display: flex
			justify-content: space-between
			align-items: center
			+mq-max(sm)
				flex-direction: column-reverse
				align-items: flex-start
				gap: r(12px)
			figure
				align-items: center
				display: flex
				figcaption
					display: block
					flex: auto
					padding-left: r(8px)
					margin-left: r(8px)
					border-left: 1px solid color(blue)
					+fz-lh(12px,16px)
			a
				display: flex
				align-items: center
				em
					+flex-width(r(32px))
					+bg(blue)
					+circle(r(32px))
					+text(white)
					display: flex
					+flex-center
				span
					flex: auto
					padding-left: r(8px)
					@extend .text-body-4
