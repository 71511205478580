.about-bg-1
	padding-top: r(152px)
	background-image: linear-gradient(180deg, rgba(238, 245, 247, 0) 0%, #EEF5F7 100%)
	background-position: bottom
	position: relative
	overflow: hidden
	&::before
		position: absolute
		content: ''
		border-bottom: r(32px) solid color(green)
		width: 100%
		z-index: 2
		bottom: 0
		left: 0
	+mq-max(lg)
		padding-top: r(40px)

.about-3
	.img
		figure
			border-radius: r(32px)
			overflow: hidden
			height: 100%
			img
				+fit-cover
	.item-list
		+content-margins
			margin-top: r(32px)
			+mq-max(lg)
				margin-top: r(20px)
		.item
			padding: r(48px) r(60px)
			+bg(blue-greyish-light)
			border-radius: r(32px)
			+mq-min(lg)
				height: calc(50% - 1rem)
			+mq-max(lg)
				padding: r(32px)
			+content-margins
				margin-top: r(20px)
			.icon
				span
					font-size: r(32px)
					+text(main)
