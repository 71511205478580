.home-news-tabs
	.tab-nav
		+flex-gap(r(8px))
		width: fit-content
		margin: 0 auto r(60px)
		justify-content: center
		padding: r(4px)
		+bg(grey-bright)
		border-radius: 999px
		+mq-max(lg)
			margin-bottom: r(30px)
		li
			a
				display: inline-flex
				+flex-center
				@extend .text-body-4
				+text(grey-666)
				min-height: r(32px)
				padding: r(7px) r(17px)
				border-radius: r(16px)
				+trans-all
				+parent-state(".active")
					+bg(white)
					box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05)
					+text(blue)
					font-weight: 700
				+on-hover
					+text(blue)
					+bg(white)
					box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05)
	.news-slider
		+mq-min(lg)
			max-width: r(1050px)
			margin: 0 auto
