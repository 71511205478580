.btn
	display: inline-flex
	+flex-center
	+reset-link
	padding: 0 1rem
	height: r(48px)
	min-width: r(180px)
	text-transform: uppercase
	cursor: pointer
	transition: 0.3s all
	gap: r(16px)
	&-pill
		+radius(100px)
		&:before
			+radius(100px)

	
	
$threshold: 55%

@function contrast-color($color, $dark, $light)
	@return if(lightness($color) < $threshold, $light, $dark)
    
@each $color, $value in $colors
	.btn-#{$color}
		background-color: $value
		color: contrast-color($value, color(body), color(white))
		+on-hover
			background-color: darken($value, 10%)

	.btn-border-#{$color}
		border: 1px solid $value
		color: color($color)
		position: relative
		overflow: hidden
		z-index: 1
		&:before
			position: absolute
			content: ''
			top: 0
			left: 0
			height: 100%
			width: 0
			+trans-all
			+bg($color)
			z-index: -1
		+on-hover
			color: contrast-color($value, color(body), color(white))
			&:before
				width: 100%

// @each $gradient, $value in $gradients
// 	.btn-#{$gradient}
// 		background: $value

.btn-circle
	+flex-center
	+reset-link
	display: inline-flex
	+circle(r(50px))
	font-size: r(24px)
	transition: 0.3s all

.swiper-btn
	display: inline-flex
	+flex-center
	+circle(r(60px))
	+text(blue)
	font-size: r(32px)
	border: 1px solid color(blue)
	position: absolute
	+center(Y)
	cursor: pointer
	z-index: 2
	transition: 0.3s all
	+remove(lg,max)
	+mq-min(lg)
		margin: 0 r(30px)
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	+on-hover
		+bg(blue)
		+text(white)