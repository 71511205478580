.footer-top
	padding: r(90px) 0 r(80px)
	+mq-max(lg)
		padding: r(40px) 0

.footer-item
	+flex-gap-col(1rem)
	@extend .text-body-4
	h3
		@extend .text-body-1
		+text(blue)
	strong
		@extend .text-body-1
	.cta-link
		gap: r(10px)
		em
			width: r(50px)
			height: r(50px)
			+bg(blue)
			+text(white)
		strong
			flex-direction: column
			align-items: flex-start
			display: flex

.footer-worktime
	ul
		+flex-gap-col(1rem)
		li
			+flex-gap-col(r(6px))
			span,strong
				+text(blue)

.footer-links
	ul
		+flex-gap-col(r(12px))

.footer-subscription
	.subscription-title
		+flex-gap-col(1rem)

.subscribefrm
	position: relative
	button
		+reset-button
		+center(Y)
		+text(main)
		font-size: r(24px)
		position: absolute
		right: r(20px)
	input
		width: 100%
		height: r(48px)
		border-radius: r(24px)
		box-shadow: 5px 5px 25px rgba(0, 0, 0, 0.05)
		border: 0
		padding: r(12px) r(60px) r(12px) r(20px)

.social-link
	margin-top: r(30px)
	ul
		+flex-gap(r(10px))
		li
			a
				+circle(r(40px))
				display: flex
				+flex-center
				+bg(white)
				+text(blue)

.footer-bottom
	padding: r(30px) 0
	@extend .text-body-5
	+text(grey-666)
	border-top: 1px solid color(grey-light)
	+mq-max(md)
		text-align: center
