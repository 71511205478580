.about-1
	position: relative
	+mq-min(lg)
		margin: 0 r(8px) r(8px)
	.img-wrap
		figure
			+mq-max(lg)
				+img-scale(66.6667%)
			+mq-min(lg)
				height: r(610px)
				img,iframe
					+fit-cover
	.content-wrap
		+text(white)
		pointer-events: none
		+mq-max(lg)
			padding: 2rem 0
			+bg(blue)
		+mq-min(lg)
			position: absolute
			+center(Y)
			left: 0
			width: 100%
			z-index: 2
		.content
			pointer-events: all
			+mq-min(lg)
				padding-right: r(80px)
	.content-bg
		position: absolute
		height: 100%
		top: 0
		left: 0
		z-index: 1
		width: 50%
		+remove(lg,max)
		border-right: r(8px) solid #fff
		img
			+fit-cover
	.swiper-navigation
		.swiper-btn
			display: flex !important
			margin: 0 r(16px)
			+bg(blue)
			+text(white)
			&.swiper-prev
				right: auto
				left: 0
			&.swiper-next
				left: auto
				right: 0

.about-gallery-slider
	.swiper-pagination
		+flex-gap(r(10px))
		justify-content: center
		.swiper-pagination-bullet
			border-radius: 0
			+box(r(20px), 4px)
			+bg-opa(white, 0.5)
			transform: none
			opacity: 1
			transition: 0.3s all
			&.swiper-pagination-bullet-active
				width: r(60px)
				+bg-opa(white, 1)

section[class*="about-"]
				scroll-margin-top: r(209px)
				scroll-snap-type: y mandatory
