.service-item
	+bg(blue-greyish-light)
	display: flex
	flex-direction: column
	.img
		figure
			a
				+img-scale(255 / 450 * 100%)
	.caption
		display: flex
		flex-direction: column
		align-items: flex-start
		padding: r(20px) r(28px)
		flex: 1 1 0%
		.content
			margin-bottom: r(36px)
			+content-margins
				margin-top: 1rem
		.brief
			+line(5)
		.btn
			margin-top: auto