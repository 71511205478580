.contact-info
	+content-margins
		margin-top: r(8px)
	.item
		display: flex
		.icon
			height: r(60px)
			display: flex
			+flex-width(r(80px))
			+flex-center
			+bg(main)
			+text(white)
			font-size: r(30px)
		.content
			flex: 1

		.title
			height: r(60px)
			display: flex
			align-items: center
			padding: 0 1rem
			+bg(white)
			&.background-blue
				+bg(blue)
				+text(white)

		.brief
			padding: r(8px) 1rem r(20px)
			+bg(white)
			+fz-lh(18px,24px)
			ul
				+content-margins
					margin-top: 1rem
				li
					display: flex
					span
						font-size: r(16px)
						margin-right: r(12px)
						position: relative
						top: r(4px)
						+text(blue)

.contact-form
	.row
		+gap-override(20px)
	.form-group
		input[type="text"], input[type="date"], select, textarea
			border: 1px solid #e5e5e5
	.frm-btnwrap
		margin-top: r(20px)
	.frm-btn
		input
			border-radius: 999px !important

.contact-section
	position: relative
	z-index: 1
	+mq-min(lg)
		&::after
			+pseudo
			top: 0
			right: 0
			width: 1035 / 1920 * 100%
			height: 100%
			+bg(white)
			z-index: -1