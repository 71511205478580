.support-side-nav
	+mq-min(lg)
		padding-right: r(30px)
	.heading-5
		border-bottom: 1px solid color(grey-light)
	ul
		li
			padding-bottom: 1rem
			margin-bottom: 1rem
			border-bottom: 1px solid color(grey-light)
			a
				+fz-lh(16px,20px)
				+text(grey-666)
				+parent-state(".active")
					+text(main)

.support-content-wrap
	padding: r(48px) r(60px)
	+mq-max(lg)
		padding: r(20px)
	+bg(blue-greyish-light)
	.step-tab
		display: flex
		justify-content: space-between
		position: relative
		z-index: 1
		+mq-min(lg)
			&::after
				+pseudo
				z-index: -1
				position: absolute
				background: linear-gradient(270deg, rgba(217, 217, 217, 0) 0%, rgba(217, 217, 217, 0.414969) 5%, #D9D9D9 49.8%, rgba(217, 217, 217, 0.414969) 94.65%, rgba(217, 217, 217, 0) 100%)
				height: 1px
				left: r(-60px)
				right: r(-60px)
				+center(Y)
		li
			a
				+circle(r(60px))
				display: flex
				+flex-center
				+bg(white)
				+fz-lh(14px,20px)
				+text(blue)
				+parent-state(".active")
					+bg(main)
					+text(white)
				+mq-max(lg)
					+circle(r(52px))
					font-size: r(12px)
	.step-table
		table
			width: 100%
			border: 1px solid color(grey-light)
			+bg(white)
			td,th
				padding: r(12px) r(28px)
				text-align: left
				border-right: 1px solid color(grey-light)
				+fz-lh(16px,24px)
				+mq-max(lg)
					padding: r(12px) 1rem
			thead
				tr
					th
						+bg(blue)
						+text(white)