.news-item
	position: relative
	display: block
	overflow: hidden
	border-radius: r(10px)
	.news-img
		figure
			+img-scale(100%)
	.news-caption
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		padding: r(20px)
		+trans-all
		+bg-opa(black, 0.33)
		+text(white)
		+flex-gap-col(r(10px))
		align-items: flex-start
	.news-cate
		display: inline-block
		padding: r(4px) r(8px)
		border-radius: r(5px)
		+bg(white)
		+text(body)
	.news-title
		+line(2)
		height: r(48px)
	+on-hover
		.news-caption
			padding-bottom: r(40px)
			+bg-opa(main, 0.9)
