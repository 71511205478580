.home-banner
	position: relative
	.banner-item
		.banner-img
			figure
				+mq-min(xl)
					height: 100vh
				img
					+fit-cover
	.swiper-pagination-wrap
		position: absolute
		right: 0
		z-index: 2
		bottom: r(100px)
		width: 100%
		+mq-max(lg)
			bottom: r(30px)
	.swiper-pagination
		position: static
		+flex-gap(r(10px))
		justify-content: flex-end
		+mq-max(lg)
			justify-content: center
		.swiper-pagination-bullet
			border-radius: 0
			+box(r(20px), 4px)
			+bg-opa(white, 0.5)
			transform: none
			opacity: 1
			transition: 0.3s all
			&.swiper-pagination-bullet-active
				width: r(60px)
				+bg-opa(white, 1)

.request-form-wrapper
	padding: r(30px) 0
	+mq-min(lg)
		position: absolute
		bottom: r(40px)
		width: 100%
		left: 0
		z-index: 2
		padding: 0


.request-form
	padding: r(20px) r(25px)
	+radius(r(10px))
	+bg-opa(main, 0.75)
	.form-row
		+flex-gap(r(15px))
		+mq-max(lg)
			flex-wrap: wrap
			justify-content: center
	.form-group
		flex: 1
		+mq-max(lg)
			+flex-width(calc(50% - 7.5px))
		+mq-max(sm)
			+flex-width(calc(100% - 7.5px))
	.frm-btnwrap
		margin-top: 0
	.frm-btn
		width: 100%