.about-2
	position: relative
	+mq-min(lg)
		margin: 0 r(8px) r(8px)
	.img-wrap
		.img
			+mq-min(lg)
				border-right: r(8px) solid #fff
		figure
			+mq-min(lg)
				height: r(610px)
				img
					+fit-cover
	.content-wrap
		+mq-max(lg)
			padding: 2rem 0
			+bg(blue-greyish-light)
		+mq-min(lg)
			position: absolute
			+center(Y)
			left: 0
			width: 100%
			z-index: 2
	.content
		+mq-min(lg)
			padding-left: r(80px)
	.content-bg
		position: absolute
		height: 100%
		top: 0
		right: 0
		z-index: 1
		width: 50%
		+remove(lg,max)
		img
			+fit-cover
	.item-list
		ul
			line-height: 1.333333
			+content-margins
				margin-top: r(8px)
			li
				display: flex
				padding: r(20px)
				+bg(white)
				+text(blue)
				align-items: center
				font-weight: 300
				border-left: 1px solid color(main)
				em
					font-size: r(24px)
					+flex-width(r(24px))
					margin-right: r(24px)