.home-intro
	.item-list
		.row
			+gap-override(25px)
			+mq-max(sm)
				+gap-override(10px)
		.item-col
			.item
				+trans-all
				aspect-ratio: 1
				+flex-gap-col(r(15px))
				padding: r(40px)
				border-radius: r(50px)
				+text(white)
				+mq-max(lg)
					padding: r(24px)
					border-radius: r(30px)
				.icon
					font-size: r(64px)
					+trans-all
					+parent-state(":hover")
						transform: translateY(-10px)
			+mq-max(md)
				.item
					+bg(green)
				&:nth-child(4n+1),&:nth-child(4n+4)
					.item
						+bg(olive)
			+mq-min(md)
				+odd
					.item
						+bg(olive)
				+even
					.item
						+bg(green)
				&:nth-child(5n+3)
					.item
						+bg(blue-greyish)
