.news-sub-nav
	padding: r(4px) r(12px)
	+bg(grey-bright)
	+mq-max(lg)
		padding: r(8px)
	ul
		+mq-max(lg)
			display: flex
			overflow-x: auto
		+mq-min(lg)
			+row(8px)
		li
			a
				display: flex
				+flex-center
				text-align: center
				height: 2rem
				border-radius: 999px
				padding: 0 r(12px)
				@extend .text-body-4
				white-space: nowrap
				+text(grey-666)
				+parent-state(".active")
					+bg(white)
					+text(blue)
					font-weight: 700