.homepage
	.global-header
		position: fixed
		top: 0
		left: 0
		width: 100%
		z-index: 99
		+mq-max(lg)
			position: relative
	
.global-header
	background: linear-gradient(180deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.8) 100%)
	backdrop-filter: blur(10px)
	position: relative
	z-index: 11

.header-top
	padding: r(15px) 0
	position: relative

.logo
	+mq-max(lg)
		img
			height: 40px

.search-wrapper
	+mq-min(lg)
		.container
			max-width: none
			padding: 0
	+mq-max(lg)
		position: absolute
		top: 100%
		left: 0
		width: 100%
		padding: r(15px) 0
		display: none
		+bg-opa(white, 0.75)
		z-index: 1

.searchbox
	position: relative
	input
		width: 100%
		height: r(48px)
		border-radius: 100px
		padding: r(12px) r(20px) r(12px) r(50px)
		border: 1px solid color(main)
		+placeholder
			+text(grey-666)
	button
		+reset-button
		position: absolute
		+center('Y')
		left: r(20px)
		em
			+text(main)

.header-util
	+flex-gap(r(30px))
	justify-content: flex-end
	line-height: r(20px)
	+mq-max(xxl)
		gap: r(15px)
	+mq-max(lg)
		gap: r(10px)

.header-util-divider
	border-left: 1px solid color(grey-light)
	height: r(44px)
	+remove(lg,max)

.cta-link
	+flex-gap(r(16px))
	align-items: center
	+text(blue)
	+mq-max(xxl)
		gap: r(8px)
	em
		display: flex
		+flex-center
		+circle(r(44px))
		+border($color: blue)
		font-size: r(20px)
	strong
		flex: 1
		text-transform: uppercase
		+mq-min(xl)
			align-self: center
			display: flex
			align-items: center
			gap: r(8px)
		span
			+text(main)
			+mq-max(xl)
				display: block

.language.dropdown
	display: flex
	align-items: center
	height: 100%
	+fz-lh(14px,18px)
	+mq-max(lg)
		+fz-lh(20px,28px)
	.current
		+text(grey-999)
		+flex-gap(r(4px))
		align-items: center
		.text
			+text(blue)
			+remove(lg,max)
		.fa-chevron-down
			+remove(lg,max)
		+mq-max(lg)
			display: flex
			+flex-center
			+box(r(48px), r(48px))
			font-size: r(24px)
			line-height: 1
			+bg(grey-999)
			+text(white)
	.dropdown-content
		top: 100%
		min-width: auto
		ul
			padding: r(12px) r(15px)

.menu
	ul
		+mq-min(lg)
			+flex-gap(r(10px))
		li
			+mq-max(lg)
				+after-first(1)
					margin-top: 4vw
			a
				display: inline-flex
				+flex-center
				text-align: center
				padding: 0 r(25px)
				min-height: r(50px)
				+fz-lh(18px, 22px)
				+text(blue)
				position: relative
				z-index: 1
				span
					+remove(lg,min)
				+mq-max(xl)
					font-size: r(13px)
				+mq-max(lg)
					font-size: 5.75vw
					display: flex
					min-height: 12vw
					text-align: left
					justify-content: flex-start
					+text(white)
					padding: 0 5vw
					em
						display: none
				&:before
					+pseudo
					+trbl(5px,5px,5px,5px)
					+bg(main)
					+radius-custom(r(25px) r(25px) 0px r(25px))
					z-index: -1
					opacity: 0
					+parent-state('.active')
						opacity: 1
					+mq-max(lg)
						+trbl(0,0,0,0)
						+radius-custom(6vw 6vw 0px 6vw)

.btn-toggle
	display: none
	&.search-toggle
		+bg(grey-666)
		+text(white)
	&.menu-toggle
		+bg(main)
		+text(white)
	+mq-max(lg)
		display: flex
		+flex-center
		+box(r(48px), r(48px))
		font-size: r(24px)
		line-height: 1

.mobile-menu
	position: fixed
	width: 100vw
	height: 100vh
	z-index: 100
	display: none
	top: 0
	left: 0
	background: rgba(#fff , 0.1)
	backdrop-filter: blur(5px)
	.mobile-menu-overlay
		position: absolute
		z-index: 1
		height: 100%
		width: 100%
	.mobile-menu-wrapper
		z-index: 2
		height: 100%
		width: 100%
		position: relative
		transform: translateY(-100%)
		transition: 0.3s all
		pointer-events: none
		+parent-state(".is-open")
			transform: none
	.mobile-menu-container
		position: absolute
		pointer-events: all
		+center(both)
		height: 88vh
		width: 94vw
		+radius-custom(10vw 10vw 0px 10vw)
		background: linear-gradient(180deg, rgba(33,18,97,1) 50%, rgba(46,20,147,1) 100%)
		padding: 8vw 6vw
		overflow: auto
	.logo-w
		margin-bottom: 8vw
