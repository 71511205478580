.about-5
	margin-top: r(80px)

.history-year-dot-slider
	position: relative
	&::before
		content: ''
		height: 1px
		background: color(grey-light)
		position: absolute
		top: r(20px)
		width: 100%
	+mq-min(lg)
		.swiper-container
			width: r(920px)
			margin: 0 auto
	.swiper-slide
		.year-item
			cursor: pointer
			height: r(68px)
			.dot
				+circle(r(40px))
				position: relative
				margin: 0 auto
				span
					position: absolute
					+circle(r(20px))
					+center(both)
					+bg(blue)
					+trans-all
					+context(".swiper-slide", ".swiper-slide.swiper-slide-thumb-active")
						+bg(main)
						+circle(r(40px))
			.text
				text-align: center
				margin-top: r(4px)
				+trans-all
				+context(".swiper-slide", ".swiper-slide.swiper-slide-thumb-active")
					font-size: r(24px)
					font-weight: 700
					+text(main)

.history-year-content-slider
	.swiper-navigation
		margin: r(60px) r(-4px) 0
		+remove(lg,max)
		.swiper-btn
			position: static
			transform: none
			border-color: color(white)
			+text(white)
			margin: 0 r(4px)
	+mq-max(lg)
		padding: r(40px) 0
	+mq-min(lg)
		padding-left: r(60px)

.about-5-container
	+bg(blue)
	+text(white)
	position: relative
	.content-wrap
		+mq-min(lg)
			position: absolute
			+center(Y)
			width: 100%

	.img-wrap
		figure
			+mq-min(lg)
				height: r(580px)
				img
					+fit-cover