.service-brief-content
	+mq-min(lg)
		padding-right: r(120px)
	+content-margins
		margin-top: r(16px)

.service-detail-bottom
	margin-top: r(24px)
	+mq-min(lg)
		margin-top: r(60px)

.service-detail-table
	table
		width: 100%
		border: 1px solid color(grey-light)
		td,th
			padding: r(12px) r(28px)
			text-align: center
			border-right: 1px solid color(grey-light)
			+fz-lh(16px,24px)
			+mq-max(lg)
				padding: r(12px) 1rem
			&:nth-child(2)
				text-align: left
				width: 75%
		thead
			tr
				th
					+bg(blue)
					+text(white)
		tbody
			tr
				+even
					+bg(grey-bright)