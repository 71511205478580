.news-latest-list
    padding: r(24px) r(32px)
    +bg(blue-greyish-light)
    border-radius: r(20px)
    +mq-max(md)
        padding: r(20px)

.news-latest-item
    display: flex
    +after-first(1)
        margin-top: r(30px)
        +mq-max(md)
            margin-top: 1rem
    .img
        +flex-width(r(100px))
        border-radius: r(8px)
        overflow: hidden
        figure
            height: r(60px)
            overflow: hidden
            img
                +fit-cover
    .title
        +line(2)
        line-height: 1.333333
    .caption
        padding-left: r(20px)
        +content-margins
            margin-top: r(4px)