.home-room
	position: relative
	background-color: color(blue-light)
	+mq-min(lg)
		background-image: url(../img/home-bg-2.png)
		background-position: 100% 0
		background-repeat: no-repeat
		background-size: auto

	+mq-min(lg)
		height: 960px
		display: flex
	.home-room-detail-wrap
		+mq-min(lg)
			width: 50%
			display: flex
			box-shadow: 15px 0px 0px 0px color(main)
	
	.room-detail-item
		display: none
		width: 100%
		+first(1)
			display: block

	.room-detail-item-img
		+mq-min(lg)
			height: 100%
		figure
			+mq-max(lg)
				+img-scale(56.25%)
			+mq-min(lg)
				height: 100%
				img
					+fit-cover	

	.room-detail-item-content-wrap
		+mq-min(lg)
			position: absolute
			width: 100%
			bottom: 0
			left: 0
			pointer-events: none

	.room-detail-item-content
		+flex-gap-col(r(20px))
		align-items: flex-start
		padding: r(40px)
		background: rgba(#f5f5f5 , 0.93)
		+radius-custom(r(10px) 0px 0px 0px)
		pointer-events: all
		.text-body-3
			+line(3)

	.home-room-title-list-wrap
		+mq-min(lg)
			position: absolute
			width: 100%
			height: 100%
			top: 0
			left: 0
			pointer-events: none
			z-index: 2
			display: flex
			.row
				height: 100%
				> *
					max-height: 100%

	.section-heading
		+mq-min(lg)
			padding: r(45px) 0 r(45px) r(40px)
		.heading-1
			font-size: r(40px)

	.home-room-title-list-container
		pointer-events: all
		+mq-min(lg)
			display: flex
			flex-direction: column
			height: 100%

	.home-room-title-list
		+mq-min(lg)
			overflow: hidden
			flex: 1
			max-height: 100%
		ul
			+flex-gap-col(r(5px))
			height: 100%
			overflow-y: auto
			+scrollbars(4px, color(main), #ccc)
			
			+mq-max(lg)
				gap: r(15px)
			+mq-min(lg)
				padding-left: r(15px)

			li
				flex: 1
				.room-title
					display: flex
					min-height: r(60px)
					height: 100%
					+bg-opa(white,0.5)
					+text(blue)
					position: relative
					+trans-all
					&:hover,&.active
						+bg(white)
					+mq-max(lg)
						padding: r(15px) 0
					.room-title-icon
						padding-left: r(40px)
						padding-right: r(10px)
						+flex-width(r(80px))
						font-size: r(24px)
						z-index: 1
						display: flex
						align-items: center
						min-height: r(60px)
						height: 100%
						+trans-all
						+parent-state(":hover", ".active")
							+text(white)
						+mq-max(lg)
							+flex-width(r(60px))
							padding: 0
							justify-content: center
						&:before
							+pseudo($width: 0, $height: 100%)
							z-index: -1
							+trans-all
							+bg(main)
							left: 0
							top: 0
							bottom: 0
							+parent-state(":hover", ".active")
								width: r(80px)
								+mq-max(lg)
									width: r(60px)
					.room-title-text
						padding: 0 r(15px)
						display: flex
						align-items: center
						+fz-lh(18px, 22px)
						flex: 1
						+mq-max(lg)
							padding-right: r(60px)
							&:after
								+pseudo
								+icon('\f078')
								right: r(30px)
								+center(Y)
								+parent-state(".active")
									+icon('\f077')
				.room-content-mobile
					display: none
				.room-detail-item-content
					padding: r(24px)
					+bg(white)