.heading-1
	+fz-lh(48px, 60px)
	letter-spacing: -0.04em

.heading-4
	+fz-lh(32px, 40px)

.heading-5
	+fz-lh(24px, 32px)

.text-body-1
	+fz-lh(20px, 24px)

.text-body-2
	+fz-lh(18px, 24px)

.text-body-3
	+fz-lh(16px, 20px)

.text-body-4
	+fz-lh(14px, 20px)

.text-body-5
	+fz-lh(12px, 16px)