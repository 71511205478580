.fixed-stuff
	position: fixed
	z-index: 50
	bottom: r(55px)
	right: r(20px)
	ul
		li
			+after-first(1)
				margin-top: r(10px)
			.btn-circle
				+trans-all
			&:hover
				.btn-circle
					+bg(main)
			&.has-tooltip
				position: relative
				.btn-circle
					position: relative
					z-index: 2
				.tooltip
					position: absolute
					right: 0
					top: 0
					bottom: 0
					white-space: nowrap
					display: flex
					align-items: center
					justify-content: center
					flex-direction: column
					+bg(blue)
					+text(white)
					border-radius: 999px
					z-index: 1
					padding: 0
					overflow: hidden
					opacity: 0
					pointer-events: none
					+trans-all
					@extend .text-body-4

				&:hover
					.tooltip
						opacity: 1
						pointer-events: all
						padding: 0 r(80px) 0 r(30px)

#registerForm
	width: r(930px)
