.fadeDown
    transform: translateY(-50px)
    opacity: 0
    will-change: transform, opacity

.fadeUp
    transform: translateY(50px)
    opacity: 0
    will-change: transform, opacity

.fadeLeft
    transform: translateX(50px)
    opacity: 0
    will-change: transform, opacity

.fadeZoomIn
    transform: scale(1.15)
    opacity: 0
    will-change: transform, opacity
