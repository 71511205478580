.staff-item
	+flex-gap-col(r(15px))
	.staff-item-img
		figure
			+radius(r(10px))
			overflow: hidden
			aspect-ratio: 330 / 440
			img
				+fit-cover
	.staff-item-caption
		+flex-gap-col(r(15px))
	.staff-name
		> *
			display: block
	.staff-position
		ul
			+flex-gap-col(r(4px))
			li
				padding: r(5px) r(15px)
				+bg(blue-greyish-light)
				border-left: 2px solid color(main)
				border-radius: 2px
