//Reset
@import src/_plugins/sass-libraries/reset.sass
@import src/_plugins/sass-libraries/util.scss
// @import src/_plugins/sass-libraries/fluid-type.sass
@import src/_plugins/sass-libraries/family.scss
@import src/_plugins/sass-libraries/columnizer.scss
@import src/_plugins/sass-libraries/svg-background.scss

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	line-height: 1
	font-family: 'Lexend', sans-serif
	color: #333
	font-size: 3.2vw
	+mq-min(md)
		font-size: 1.5vw
	+mq-min(lg)
		font-size: 14px
	+mq-min(xxl)
		font-size: 16px

*,*:before,*:after
	box-sizing: border-box
	outline: none
	-webkit-font-smoothing: antialiased

a
	text-decoration: none

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Anek Latin', sans-serif
	&:before
		font-family: 'Font Awesome 5 Pro'

.frm-btn-reset
	display: none !important

.frm-btnwrap
	.label
		display: none

.fancybox-close-small
	width: auto
	svg
		width: r(48px)
		height: r(48px)
		path
			fill: color(main)

.fancybox-container
	z-index: 4999
