.about-7
	ul.tabs-nav
		padding: r(4px)
		+bg(grey-bright)
		display: inline-flex
		border-radius: 999px
		overflow: auto
		+mq-max(sm)
			width: 100%
		li
			a
				display: flex
				+flex-center
				text-align: center
				height: 2rem
				border-radius: 999px
				padding: 0 r(12px)
				@extend .text-body-4
				white-space: nowrap
				+text(grey-666)
				+parent-state(".active")
					+bg(white)
					+text(blue)
					font-weight: 700
	.gallery-item
		.img-wrap
			.img
				display: block
				border-radius: r(12px)
				overflow: hidden
				figure
					+img-scale(470 / 695 * 100%)
		.row
			+gap-override(20px)
			.col-lg-6
				&:nth-child(1)
					.img-wrap
						.img
							figure
								+img-scale(470 / 680 * 100%)
				&:nth-child(2)
					display: flex
					flex-wrap: wrap
					margin-bottom: r(-20px)
					padding-left: 0
					> *
						flex: 0 0 50%
						max-width: 50%
						padding-left: r(20px)
						padding-bottom: r(20px)