.home-service
	overflow: hidden
	.section-heading
		gap: r(10px)

.home-service-slider
	+mq-min(xl)
		padding-left: r(20px)
	.swiper-container
		overflow: visible
	.swiper-slide
		height: auto
	.swiper-scrollbar
		height: r(4px)
		margin-top: r(45px)
		background: #BEC8C5
		+remove(lg,max)
		.swiper-scrollbar-drag
			+bg(main)
			cursor: grab
	.home-service-item
		height: 100%
		+mq-max(xl)
			background: rgba(245, 245, 245, 0.93)
			+radius(r(10px))
			overflow: hidden
		+mq-min(xl)
			display: flex
			justify-content: flex-end
		.home-service-img
			+mq-min(xl)
				+flex-width(930 / 1150 * 100%)
			figure
				overflow: hidden
				+mq-max(xl)
					aspect-ratio: 930 / 620
				+mq-min(xl)
					height: r(620px)
					+radius(r(10px))
				img
					+fit-cover
		.home-service-content
			+flex-gap-col(r(20px))
			padding: r(24px)
			+mq-min(xl)
				background: rgba(245, 245, 245, 0.93)
				position: absolute
				bottom: 0
				left: 0
				width: 500 / 1150 * 100%
				padding: r(40px)
				+trans-all
				+radius-custom(r(40px) r(40px) 0 r(40px))
				+parent-state(':hover')
					background: #fff
			+mq-min(xxl)
				width: 400 / 1150 * 100%
		.home-service-brief
			ul
				+flex-gap-col(r(10px))
				li
					+flex-gap(r(20px))
					align-items: center
					padding: r(10px) 0 r(20px)
					border-bottom: 1px solid #BEC8C5
					em
						+flex-width(32px)
						font-size: r(32px)
						+text(main)