.about-4
	margin-top: r(72px)
	+mq-max(lg)
		margin-top: r(40px)
	.img
		margin-bottom: 0
		position: relative
		z-index: 1
	.item-list
		border-bottom: 1px solid color(grey-light)
		.item
			padding: r(20px) 0
			border-top: 1px solid color(grey-light)
			+each(1)
				.title
					+text(green)
			+each(2)
				.title
					+text(blue-greyish)
			+each(3)
				.title
					+text(olive)
