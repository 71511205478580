@mixin columnizer($params...) {
    $columns: nth($params, 1);
    $gutter: null;
    $fill: false;
    display: flex;
    flex-wrap: wrap;
    > * {
        @if length($params) == 1 {
            flex: 0 0 calc(100% / #{$columns});
            margin-bottom: 0;
            &:not(:last-child) {
                margin-right: 0;
            }
        } @else if length($params) == 2 {
            @if type-of(nth($params, 2)) == bool {
                $fill: nth($params, length($params));
                flex-grow: #{if($fill == true, 1, 0)};
                flex-shrink: 0;
                flex-basis: calc(100% / #{$columns});
                margin-bottom: 0;
                &:not(:last-child) {
                    margin-right: 0;
                }
            } @else if type-of(nth($params, 2)) == number {
                $gutter: nth($params, length($params));
                flex-grow: 0;
                flex-shrink: 0;
                flex-basis: calc(
                    (100% - (#{$columns} - 1) * #{$gutter}) / #{$columns}
                );
                margin-bottom: $gutter;
                &:not(:last-child) {
                    margin-right: $gutter;
                }
                &:nth-child(#{$columns}n) {
                    margin-right: 0;
                }
            }
        } @else if length($params) == 3 {
            $gutter: nth($params, 2);
            $fill: nth($params, length($params));
            flex-grow: if($fill == true, 1, 0);
            flex-shrink: 0;
            flex-basis: calc(
                (100% - (#{$columns} - 1) * #{$gutter}) / #{$columns}
            );
            margin-bottom: $gutter;
            &:not(:last-child) {
                margin-right: $gutter;
            }
            &:nth-child(#{$columns}n) {
                margin-right: 0;
            }
        }
    }
}
