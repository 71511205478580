.news-horizontal-item
	display: flex
	border-radius: r(20px)
	overflow: hidden
	+bg(white)
	box-shadow: $shadow-1
	+after-first(1)
		margin-top: r(30px)
	.news-img
		+flex-width((570 / 930 * 100%))
		figure
			height: 100%
			+img-scale(320 / 570 * 100%)
			img
				+fit-cover
		+mq-max(md)
			+flex-width(33.33333%)
			figure
				min-height: r(120px)
	.news-caption
		padding: r(32px) r(36px)
		+mq-max(md)
			padding: r(12px) r(16px)
		+content-margins
			margin-top: 1rem
			+mq-max(md)
				margin-top: 0.5rem
	.news-title
		+line(3)
	.news-brief
		+line(4)
		+remove(lg,max)
