.about-sub-nav
	padding: r(12px) 0
	+bg(white)
	.sub-nav
		display: flex
		align-items: center
		justify-content: space-between
		+fz-lh(18px,24px)
		+text(grey-666)
		font-weight: 300
		letter-spacing: -0.04em
		overflow-x: auto
		white-space: nowrap
		scroll-snap-type: x mandatory
		+mq-max(lg)
			+content-margins
				margin-left: r(20px)
		li
			scroll-snap-align: center
			a
				+parent-state(".active")
					+text(main)
