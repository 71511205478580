.modulepager
	margin-top: r(30px)
	.pagination
		display: flex
		align-items: center
		justify-content: center
		li
			list-style: none
			display: inline-block
			text-align: center
			margin-left: r(8px)
			&:first-child
				margin-left: 0
			a,span
				justify-content: center
				align-items: center
				display: flex
				text-decoration: none
				width: r(40px)
				height: r(40px)
				font-size: r(16px)
				+text(blue)
				+radius(50%)
				border: 1px solid color(blue)
				+trans-all
			&.active,&:hover
				a,span
					+bg(main)
					+text(blue)
					border-color: color(main)
					
			.FirstPage,.LastPage,.BackPage,.NextPage
				display: none !important
			// .BackPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		content: "\EA64"
			// 		font-size: 15px
			// .NextPage
			// 	font-size: 0
			// 	&:before
			// 		font-family: remixicon!important
			// 		font-size: 15px
			// 		content: "\EA6E"

.no-pagination
	.pages
		display: none