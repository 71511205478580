.department-detail-1
	position: relative
	margin: 0 r(8px)
	.img
		+mq-min(lg)
			border-right: r(8px) solid #fff
		figure
			+mq-min(lg)
				height: r(610px)
				img
					+fit-cover
	.content-wrap
		+mq-max(lg)
			padding: 2rem 0
		+mq-min(lg)
			position: absolute
			+center(Y)
			width: 100%
	.content
		+mq-min(lg)
			padding-left: r(40px)
		+mq-min(xl)
			padding-left: r(88px)
	.desc
		+content-margins
			margin-top: 1rem

.department-detail-2
	.item
		.img
			figure
				+img-scale(255 / 450 * 100%)