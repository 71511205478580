.home-facility
	overflow: hidden
	
.home-facility-slider
	.swiper-container
		+mq-min(lg)
			overflow: visible
	.home-facility-item
		position: relative
		.home-facility-img
			+radius(r(10px))
			overflow: hidden
			figure
				aspect-ratio: 690 / 460
				img
					+fit-cover
		.home-facility-caption
			text-align: center
			+mq-min(lg)
				+radius(r(10px))
				+fill
				+flex-gap-col(r(10px))
				+flex-center
				+bg-opa(black, 0.33)
				+text(white)
				opacity: 0
				+trans-all
				pointer-events: none
				+parent-state(":hover")
					opacity: 1
			+mq-min(xxl)
				padding: 0 r(120px)
			em
				font-size: r(64px)
				+remove(lg,max)
			.heading-4
				margin-top: r(15px)
				+mq-max(lg)
					font-size: r(24px)