.section-heading
	padding: r(60px) 0
	+flex-gap-col(r(30px))
	&.pb-30
		padding-bottom: r(30px)
	+mq-max(lg)
		padding: r(30px) 0
	.section-desc
		@extend .text-body-3

.section-heading-col
	+flex-gap-col(r(30px))
	align-items: flex-start
	+mq-min(lg)
		padding: r(10px) 0

.section
	padding: r(60px) 0
	+mq-max(lg)
		padding: 2rem 0